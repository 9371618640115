import React, { useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/Journal.css";
import {
  section_bg_img,
  bookmark,
  left_arrow,
  arrow,
  dlt_icon,
  edit_icon,
  see_more_icon,
  see_less_icon,
  calendar_icon,
  info_icon,
} from "../assets";
import Popup from "./Modal";
import {
  addEntrytoJournal,
  deleteEntrytoJournal,
  editEntrytoJournal,
} from "../_request";

const modal_data = [
  {
    title: "Your personal journal",
    desc: "Your journal is one of the most important tools in this program.",
    subHead: "There are two different ways in which you can use your journal:",
    step1:
      "1. Standard journal entries, Simply type in whatever you like as free text below.",
    step2:
      "2. Journal entries linked to the program, You might like to use your journal to reflect on things you notice and learn as you go through the program content, using the 'Comment in your journal' button which sits below each block of information.",
  },
];

const prev_entries = [
  {
    date: "6th Sep",
    title: "Where I want to be in life",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "Where I want to be in life",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "Where I want to be in life",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "How do I improve in life and do good",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "How do I improve in life and do good",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "Where I want to be in life",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "How do I improve in life and do good",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "How do I improve in life and do good",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
];

const Journal = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [textValue, setTextValue] = useState("");

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDescChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmitJournal = () => {
    var body = {
      journal_data_id: 3,
      title: title,
      description: description,
    };
    addEntrytoJournal(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        console.log(resp.ResponseMessage);
      }
    });
  };

  const handleEditJournal = (item) => {
    setIsEditing(!isEditing);
    var body = {
      journal_data_id: 3,
      entry_id: item.id,
      title: item.title,
      description: item.description,
    };
    editEntrytoJournal(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        console.log(resp.ResponseMessage);
      }
    });
  };

  const handleDeleteJournal = (item) => {
    var body = {
      journal_data_id: 3,
      entry_id: item.id,
    };
    deleteEntrytoJournal(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        console.log(resp.ResponseMessage);
      }
    });
  };

  const handleSaveClick = () => {
    // var body = {
    //   checksheet_data_id: checkSheetData?.content?.checksheet_data_id,
    //   point_name: textValue,
    // };
    // addPointToCheckSheet(body).then((resp) => {
    //   if (resp.ResponseCode === 200) {
    //     console.log(resp.ResponseMessage);
    //   }
    // });
    setIsEditing(false);
    setTextValue("");
  };

  return (
    <section className="journal">
      <section className="journal-sidebar">
        <Sidebar />
      </section>
      <section className="journal-section">
        <div className="journal-bg-img">
          <img src={section_bg_img} alt="" loading="lazy" />
        </div>
        <div className="journal-container">
          <div className="journal-sections-heading">
            Space from Depression and Anxiety
            <span className="journal-sections-heading-span">
              {" > "} Getting Started
            </span>
          </div>
          <div className="sections-progress">Section 5/6</div>
          <div className="journal-section-name">
            <div styles={{ display: "flex", gap: "1rem" }}>
              My Journal
              <img
                src={info_icon}
                alt=""
                loading="lazy"
                className="quiz-info-icon"
                onClick={() => setShowModal(true)}
              />
              <Popup
                show={showModal}
                onHide={() => setShowModal(false)}
                data={modal_data[0]}
              />
            </div>
            <div className="quiz-save">
              <div className="d-flex gap-2 align-items-center">
                <img
                  src={bookmark}
                  alt=""
                  loading="lazy"
                  className="quiz-bookmark"
                />
                <h6>Save</h6>
              </div>
            </div>
          </div>

          <div className="journal-entries">
            <div className="new-entry">
              <div className="new-entry-title">Add a new journal entry</div>
              <div className="new-entry-input-title">
                <input
                  type="text"
                  className="title-input"
                  name="title"
                  placeholder="Add title(optional)"
                  onChange={(e) => handleTitleChange(e)}
                />
              </div>
              <div className="new-entry-input-desc">
                <textarea
                  className="text-input"
                  name="thoughts"
                  placeholder="Write your thoughts..."
                  rows="50"
                  cols="50"
                  onChange={(e) => handleDescChange(e)}
                ></textarea>
              </div>
              <div className="new-entry-btn" onClick={handleSubmitJournal}>
                Submit
              </div>
            </div>

            <div className="previous-entries">
              <div className="prev-entries-header">
                <div className="prev-entries-title">Previous Entries</div>
                <div className="prev-entries-date">
                  <img
                    src={calendar_icon}
                    alt=""
                    loading="lazy"
                    className="calendar-icon"
                  />
                  Sep 11-Sep 20
                </div>
              </div>
              <div className="prev-entries-items">
                {prev_entries.map((item, i) => (
                  <div className="prev-entries-item">
                    <div className="prev-item-date">{item.date}</div>
                    <div className="prev-item-content">
                      <div className="prev-item-text">
                        <div className="prev-item-title">
                          <img
                            src={selected === i ? see_less_icon : see_more_icon}
                            alt=""
                            loading="lazy"
                            className="see-more-icon"
                            onClick={() => toggle(i)}
                          />
                          <div className="prev-item-title-text">
                            {i === selected
                              ? item.title
                              : `${item.title.slice(0, 18)}...`}
                          </div>
                        </div>
                        <div
                          className={
                            selected === i
                              ? "prev-item-desc show"
                              : "prev-item-desc"
                          }
                        >
                          <p>{item.desc}</p>
                        </div>
                      </div>

                      <div className="prev-item-icons">
                        {isEditing ? (
                          <div className="add-step-textarea">
                            <textarea
                              className="add-step-text"
                              value={textValue}
                              onChange={(e) => setTextValue(e.target.value)}
                              rows="1" // Set the number of rows as needed
                              cols="30" // Set the number of columns as needed
                            />
                            <div
                              className="add-step-save-text"
                              onClick={handleSaveClick}
                            >
                              Save
                            </div>
                          </div>
                        ) : (
                          <img
                            src={edit_icon}
                            alt=""
                            loading="lazy"
                            className="edit-icon"
                            onClick={() => handleEditJournal(item)}
                          />
                        )}
                        {/* <img
                          src={edit_icon}
                          alt=""
                          loading="lazy"
                          className="edit-icon"
                          onClick={() => handleEditJournal(item)}
                        /> */}
                        <img
                          src={dlt_icon}
                          alt=""
                          loading="lazy"
                          className="dlt-icon"
                          onClick={() => handleDeleteJournal(item)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="mood-monitor-btn">
            <div className="prev-btn">
              <div className="modules-prev-text">Previous Module</div>
              <div className="prev-arrow">
                <img
                  src={left_arrow}
                  alt=""
                  loading="lazy"
                  className="arrow-img"
                />
              </div>
            </div>
            <div className="next-btn">
              <div className="modules-text">Next Module</div>
              <div className="next-arrow">
                <img src={arrow} alt="" loading="lazy" className="arrow-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Journal;
