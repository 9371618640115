import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "bootstrap/dist/css/bootstrap.min.css";

const PER_PAGE = 16;

export default function Pagination({ count, data, setcurrPage, currentPage }) {
  function handlePageClick({ selected: selectedPage }) {
    setcurrPage(selectedPage);
  }

  const pageCount = Math.ceil(count / PER_PAGE);

  return (
    <div className="m-3 pb-4">
      <ReactPaginate
        previousLabel={<span style={{ fontSize: "14px" }}>← Previous</span>}
        nextLabel={<span style={{ fontSize: "14px" }}>Next →</span>}
        pageCount={pageCount}
        forcePage={currentPage}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    </div>
  );
}
