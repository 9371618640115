export const stellaRealDummyData = [
  {
      "journey": "anger_management",
      "expert_info": "Hope you are having a good day! Would you mind sharing a few details?",
      "total_score": 63,
      "questions": [
        {
          "question_id": 1,
          "question": "Which age group do you belong to?",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "Less than 29",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "30 - 39",
              "score": 0
            },
            {
              "button_id": "3",
              "text": "40 - 49",
              "score": 0
            },
            {
              "button_id": "4",
              "text": "50 +",
              "score": 0
            }
          ],
          "require_response":false,
          "send_response":false,
          "add_score": false
        },
        {
          "question_id": 2,
          "question": "What gender do you identify as?",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "Male",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "Female",
              "score": 0
            },
            {
              "button_id": "3",
              "text": "Prefer not to say",
              "score": 0
            },
            {
              "button_id": "4",
              "text": "Other",
              "score": 0
            }
          ],
          "require_response":false,
          "send_response":false,
          "add_score": false
        },
        {
          "question_id": 3,
          "question": "Which hierarchical level in your organization do you belong to?",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "Junior Management",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "Middle Management",
              "score": 0
            },
            {
              "button_id": "3",
              "text": "Senior Management",
              "score": 0
            }
          ],
          "require_response":false,
          "send_response":false,
          "add_score": false
        },
        {
          "question_id": 4,
          "question": "Thanks for answering all the questions. Next, I will be administering questions from the CAS test authored by Snell, W. E., Jr., Gum, S., Shuck, R. L., Mosley, J. A., & Hite, T. L. Please note, this assessment would not be a diagnosis but is for your own understanding. If you feel the results are incorrect, then your judgement is correct and the CAS assessment administered by me was wrong.",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "Ok",
              "score": 0
            }
          ],
          "require_response":false,
          "send_response":true,
          "add_score": false
        },
        {
          "question_id": 5,
          "question": "1",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I do not feel angry.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "I feel angry.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "I am angry most of the time now.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I am so angry and hostile all the time that I can't stand it.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 6,
          "question": "2",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I am not angry about my future.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "When I think about my future, I feel angry.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "I feel angry about what I have to look forward to.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I feel intensely angry about my future, since it cannot be improved.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 7,
          "question": "3",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "It makes me angry that I feel like such a failure.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "It makes me angry that I have failed more than the average person.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "As I look back on my life, I feel angry about my failures.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "It makes me angry to feel like a complete failure as a person.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 8,
          "question": "4",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I am not very angry.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "I am becoming more hostile about things than I used to be.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "I am pretty angry about things these days.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I am angry and hostile about everything.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 9,
          "question": "5",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I'm not very hostile.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "I feel hostile a good deal of the time.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "I feel quite hostile most of the time.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I feel hostile all of the time.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 10,
          "question": "6",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I don't feel that others are trying to annoy me.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "At times I think people are trying to annoy me.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "More people than usual are beginning to make me feel angry.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I feel that others are constantly and intentionally making me angry.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 11,
          "question": "7",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I don't feel angry when I think about myself.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "I feel more angry about myself these days than I used to.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "I feel angry about myself a good deal of the time.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "When I think about myself, I feel intense anger.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 12,
          "question": "8",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I don't have angry feelings about others having screwed up my life.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "It's beginning to make me angry that others are screwing up my life.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "I feel angry that others prevent me from having a good life.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I am constantly angry because others have made my life totally miserable.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 13,
          "question": "9",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I'm not violent.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "Sometimes I am so angry that I feel like hurting others, but I would not really do it.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "My anger is so intense that I sometimes feel like hurting others.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I'm so angry that I would like to hurt someone.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 14,
          "question": "10",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I don't shout at people any more than usual.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "I shout at others more now than I used to.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "I shout at people all the time now.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I shout at others so often that sometimes I just can't stop.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 15,
          "question": "11",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "Things are not more irritating to me now than usual.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "I feel slightly more irritated now than usual.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "I feel irritated a good deal of the time.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I'm irritated all the time now.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
                  {
          "question_id": 16,
          "question": "You're halfway there! Just 10 more questions to go.",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "Ok",
              "score": 0
            } ],
          "require_response":true,
          "send_response":true,
          "add_score": false
        },

        {
          "question_id": 17,
          "question": "12",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "My anger does not interfere with my interest in other people.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "My anger sometimes interferes with my interest in others.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "I am becoming so angry that I don't want to be around others.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I'm so angry that I can't stand being around people.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 18,
          "question": "13",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I don't have any persistent angry feelings that influence my ability to make decisions.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "My feelings of anger occasionally undermine my ability to make decisions.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "I am angry to the extent that it interferes with my making good decisions.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I'm so angry that I can't make good decisions anymore.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 19,
          "question": "14",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I'm not so angry and hostile that others dislike me.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "People sometimes dislike being around me since I become angry.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "More often than not, people stay away from me because I'm so hostile and angry.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "People don't like me anymore because I'm constantly angry all the time.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 20,
          "question": "15",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "My feelings of anger do not interfere with my work.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "From time to time my feelings of anger interfere with my work.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "I feel so angry that it interferes with my capacity to work.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "My feelings of anger prevent me from doing any work at all.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 21,
          "question": "16",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "My anger does not interfere with my sleep.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "Sometimes I don't sleep very well because I'm feeling angry.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "My anger is so great that I stay awake 1—2 hours later than usual.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I am so intensely angry that I can't get much sleep during the night.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 22,
          "question": "17",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "My anger does not make me feel anymore tired than usual.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "My feelings of anger are beginning to tire me out.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "My anger is intense enough that it makes me feel very tired.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "My feelings of anger leave me too tired to do anything.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 23,
          "question": "18",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "My appetite does not suffer because of my feelings of anger.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "My feelings of anger are beginning to affect my appetite.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "My feelings of anger leave me without much of an appetite.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "My anger is so intense that it has taken away my appetite.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 24,
          "question": "19",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "My anger doesn't affect my health.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "My feelings of anger are beginning to interfere with my health.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "My anger prevents me from devoting much time and attention to my health.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I'm so angry at everything these days that I pay no attention to my health and well-being.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 25,
          "question": "20",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "My ability to think clearly is unaffected by my feelings of anger.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "Sometimes my feelings of anger prevent me from thinking in a clear-headed way.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "My anger makes it hard for me to think of anything else.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I'm so intensely angry and hostile that it completely interferes with my thinking.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        },
        {
          "question_id": 26,
          "question": "21",
          "question_type": "single_select",
          "options": [
            {
              "button_id": "1",
              "text": "I don't feel so angry that it interferes with my interest in sex.",
              "score": 0
            },
            {
              "button_id": "2",
              "text": "My feelings of anger leave me less interested in sex than I used to be.",
              "score": 1
            },
            {
              "button_id": "3",
              "text": "My current feelings of anger undermine my interest in sex.",
              "score": 2
            },
            {
              "button_id": "4",
              "text": "I'm so angry about my life that I've completely lost interest in sex.",
              "score": 3
            }
          ],
          "require_response":true,
          "send_response":false,
          "add_score": true
        }
      ],
      "recommendation": [
        {
          "lower": 0,
          "upper": 13,
          "text": "Your scores indicate minimal levels of clinical anger.",
          "expert_suggestion":[
            {
              "title":"Healing With Meditation",
              "exp_image":"https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fcourses%2Fthumbnails%2FHealing%20With%20Meditation-min.png?alt=media",
              "session_link":"https://my.unitedwecare.com/course/details/2/?utm_source=com&utm_medium=website&utm_campaign=search&utm_content=course_button_click",
              "experience":"",
              "profession":"",
              "type":"Course",
              "language":["English"]
            },
            {
              "title":"Meditation for a 9-5 Life",
              "exp_image":"https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fcourses%2Fthumbnails%2FGet%20Started%20With%20Mindfulness-min.png?alt=media",
              "session_link":"https://my.unitedwecare.com/course/details/55/?utm_source=com&utm_medium=website&utm_campaign=search&utm_content=course_button_click",
              "experience":"",
              "profession":"",
              "type":"Course",
              "language":["English"]
            }
          ]
        },
        {
          "lower": 14,
          "upper": 19,
          "text": "Your scores indicate mild clinical anger.",
          "expert_suggestion":[
            {
              "title":"Healing With Meditation",
              "exp_image":"https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fcourses%2Fthumbnails%2FHealing%20With%20Meditation-min.png?alt=media",
              "session_link":"https://my.unitedwecare.com/course/details/2/?utm_source=com&utm_medium=website&utm_campaign=search&utm_content=course_button_click",
              "experience":"",
              "profession":"",
              "type":"Course",
              "language":["English"]
            },
            {
              "title":"Meditation for a 9-5 Life",
              "exp_image":"https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fcourses%2Fthumbnails%2FGet%20Started%20With%20Mindfulness-min.png?alt=media",
              "session_link":"https://my.unitedwecare.com/course/details/2/?utm_source=com&utm_medium=website&utm_campaign=search&utm_content=course_button_click",
              "experience":"",
              "profession":"",
              "type":"Course",
              "language":["English"]
            }
          ]
        },
        {
          "lower": 20,
          "upper": 28,
          "text": "Your scores indicate moderate levels of clinical anger.",
          "expert_suggestion":[
            {
              "title":"Seema Taneja",
              "exp_image":"https://app.uwc.world/Content/Upload/2021/2/ewexmyrvfjx.jpg",
              "session_link":"https://my.unitedwecare.com/profession/Psychologist/SEEMA%20TANEJA/5833",
              "experience":"30 years",
              "profession":"Psychologist, therapy, self help + relaxation techniques",
              "type":"Professional",
              "language":["English","Hindi","Bengali"]
            },
            {
              "title":"Preethi Jain",
              "exp_image":"https://app.uwc.world/Content/Upload/2022/10/tce5qjlocdg.jpg",
              "session_link":"https://my.unitedwecare.com/profession/Psychologist/Preethi%20Jain%20/29246",
              "experience":"15 years",
              "profession":"Psychologist, therapy, self help + relaxation techniques",
              "type":"Professional",
              "language":["English","Hindi","Bengali"]
            },
            {
              "title":"Bhavna Barmi",
              "exp_image":"https://app.uwc.world/Content/Upload/2021/2/np5mlr20qec.jpg",
              "session_link":"https://my.unitedwecare.com/profession/Psychologist/Bhavna%20Barmi/7412",
              "experience":"25 years",
              "profession":"Psychologist, therapy, self help + relaxation techniques",
              "type":"Professional",
              "language":["English","Hindi","Bengali"]
            }
          ]
        },
        {
          "lower": 28,
          "upper": 63,
          "text": "Your results indicate severe clinical anger.",
          "expert_suggestion":[
            {
              "title":"Jateen Ukrani",
              "exp_image":"https://app.uwc.world/Content/Upload/2022/6/jd2k3i511y1.jpg",
              "session_link":"https://my.unitedwecare.com/profession/Psychiatrist/Jateen%20Ukrani/24400",
              "experience":"10 years",
              "profession":"Psychiatrist",
              "type":"Professional",
              "language":["English","Hindi","Bengali"]
            },
            {
              "title":"Dr Sneha V",
              "exp_image":"https://app.uwc.world/Content/Upload/2023/3/jgzikkyl4c5.jpg",
              "session_link":"https://my.unitedwecare.com/profession/Psychiatrist/Dr%20Sneha%20V/30803",
              "experience":"03 years",
              "profession":"Psychiatrist",
              "type":"Professional",
              "language":["English","Hindi","Bengali"]
            },
            {
              "title":"Anuj Khandelwal",
              "exp_image":"https://app.uwc.world/Content/Upload/2022/6/u51aji41jyv.jpg",
              "session_link":"https://my.unitedwecare.com/profession/Psychiatrist/Anuj%20Khandelwal/20746",
              "experience":"10 years",
              "profession":"Psychiatrist",
              "type":"Professional",
              "language":["English","Hindi","Bengali"]
            },
          ]
        }
      ]
    }
]