import React, { useEffect, useState } from "react";
import "../styles/Modules.css";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Sidebar from "./Sidebar";
import {
  book,
  document,
  smiley,
  headphone,
  video,
  bookmark,
  module_bg_img,
  arrow,
  home_wp,
  home_copy,
} from "../assets";
import { useNavigate, useParams } from "react-router-dom/dist";
import { getCourseContent } from "../_request";
import { menu_book } from "@mui/icons-material";

const data = [
  {
    icon: <VideocamOutlinedIcon />,
    text: "Welcome to United We Care!",
  },
  {
    icon: <VideocamOutlinedIcon />,
    text: "Start questionnaire with STELLA!",
  },
  {
    icon: <DescriptionOutlinedIcon />,
    text: "How can CBT help you?",
  },
  {
    icon: <SentimentSatisfiedOutlinedIcon />,
    text: "Mood Monitor",
  },
  {
    icon: <MenuBookIcon />,
    text: "Checksheet",
  },
  {
    icon: <HeadphonesOutlinedIcon />,
    text: "How to overcome depression?",
  },
];

const Modules = () => {
  const [active, setActive] = useState("true");
  const [courseContent, setCourseContent] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentLevelId, setCurrentLevelId] = useState(id); // Track the current level ID
  // const activeHandler() => {
  //   if(active === )
  // }

  var body = { level_id: currentLevelId };
  useEffect(() => {
    getCourseContent(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCourseContent(resp?.data);
        console.log("resp?.dataresp?.data", resp?.data);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, [currentLevelId]);

  const handleClickOnLevel = (item) => {
    navigate(`/course-internal/module/${item?.id}`);
  };

  const handleNextModule = () => {
    // Increase the level_id and trigger the API call
    setCurrentLevelId(parseInt(currentLevelId, 10) + 1);
  };

  return (
    <section style={{ display: "flex", overflow: "hidden" }}>
      <section className="modules-sidebar">
        <Sidebar />
      </section>
      <section className="modules-section">
        <div>
          <div className="modules-bg-img">
            <img src={module_bg_img} alt="" loading="lazy" />
          </div>
          <div className="modules-container">
            <div className="modules-heading">
              {courseContent?.course_name} {"  "}
              <span className="modules-heading-span">
                {"  "}
                {">"} Getting Started
              </span>
            </div>
            <div className="line"></div>

            <div className="modules-sub-heading">{courseContent?.name}</div>

            <div className="modules-sections">
              <div className="modules-sections-heading">Sections</div>
              <div className="modules-sections-items">
                {courseContent?.customLevels?.map((item, i) => (
                  <div className="modules-sections-item align-items-center" onClick={() => handleClickOnLevel(item)}>
                    <div className="items">
                      <span class="material-symbols-outlined modules-icons">
                        {
                          item?.type === "video" && <VideocamOutlinedIcon />
                          ||
                          item?.type === "audio" && <HeadphonesOutlinedIcon />
                          ||
                          item?.type === "mood monitor" && <SentimentSatisfiedOutlinedIcon />
                          ||
                          item?.type === "checkSheet" && <MenuBookIcon />
                          ||
                          <DescriptionOutlinedIcon />
                        }
                      </span>
                      <div className="modules-text">{item?.name}</div>
                    </div>
                    <div>
                      <BookmarkBorderOutlinedIcon className="modules-bookmark" />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="modules-btn" onClick={handleNextModule}>
              <div className="modules-btn-text">Next Module</div>
              <div className="arrow">
                <img src={arrow} alt="" loading="lazy" className="arrow-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Modules;
