import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import {
  section_bg_img,
  delete_icon,
  disappointed_face,
  calendar,
  arrow,
  left_arrow,
  module_bg_img,
  info_icon,
} from "../assets";
import MoodBar from "./MoodBar";
import { getMoodData, removeMoodTrackerRecord, setMoodData } from "../_request";

const moods = [
  {
    emoji: disappointed_face,
    title: "Sad",
    date: "6th Sept, 14:58",
  },
  {
    emoji: disappointed_face,
    title: "Sad",
    date: "6th Sept, 14:58",
  },
  {
    emoji: disappointed_face,
    title: "Sad",
    date: "6th Sept, 14:58",
  },
  {
    emoji: disappointed_face,
    title: "Sad",
    date: "6th Sept, 14:58",
  },
  {
    emoji: disappointed_face,
    title: "Sad",
    date: "6th Sept, 14:58",
  },
];

const MoodMonitor = () => {
  const [moodData, setMoodData] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;

  useEffect(() => {
    var body = { user_id: user_id, course_id: 644 };
    getMoodData(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setMoodData(resp?.data);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, []);

  const handelDelete = (item) => {
    var body = { user_id: user_id, course_id: 644, mood_tracker_id: item };
    removeMoodTrackerRecord(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        alert(resp?.data.message);
        window.location.reload();
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  };

  return (
    <section style={{ display: "flex", overflowX: "hidden", height: "100%" }}>
      <section className="mood-monitor-sidebar">
        <Sidebar />
      </section>
      <section className="mood-monitor-section">
        <div className="mood-monitor-container">
          <div className="mood-monitor-bg-img">
            <img src={module_bg_img} alt="" loading="lazy" />
          </div>
          <div className="mood-monitor">
            <div className="sections-heading">
              Space from Depression and Anxiety
              {">"} Getting Started
            </div>
            <div className="sections-progress">Section 4/6</div>
            <div className="mood-monitor-name">
              <h2>Mood Monitor</h2>
              <img
                src={info_icon}
                alt=""
                loading="lazy"
                className="info-icon"
              />
            </div>
            <div className="mood-monitor-desc">
              The first step towards understanding how you are feeling is to
              track your moods.
            </div>
            <div>
              <MoodBar />
            </div>
            <div className="mood-analysis-heading">
              <div>Mood analysis</div>
              <div className="mood-analysis-calendar">
                <img
                  src={calendar}
                  alt=""
                  loading="lazy"
                  className="calendar-icon"
                />
                <p>Sep 11- Sep 20</p>
              </div>
            </div>
            <div className="mood-analysis-container">
              {moodData?.mood_summary?.map((mood, i) => (
                <div className="mood-analysis-item">
                  <div className="mood-analysis-text">
                    <img src={mood?.emoji} className="mood-monitor-emoji" />
                    <p className="mood-icon">{mood?.name}</p>
                    <p>{mood?.date_time}</p>
                  </div>
                  <div onClick={() => handelDelete(mood?.id)}>
                    <img src={delete_icon} className="mood-dlt-icon" />
                  </div>
                </div>
              ))}
              {/* <div className="mood-line"></div> */}
            </div>
            <div className="mood-monitor-btn">
              <div className="prev-btn">
                <div className="modules-prev-text">Previous Module</div>
                <div className="prev-arrow">
                  <img
                    src={left_arrow}
                    alt=""
                    loading="lazy"
                    className="arrow-img"
                  />
                </div>
              </div>
              <div className="next-btn">
                <div className="modules-text">Next Module</div>
                <div className="next-arrow">
                  <img
                    src={arrow}
                    alt=""
                    loading="lazy"
                    className="arrow-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default MoodMonitor;
