import React, { useEffect, useState } from "react";
import "../styles/Sections.css";
import Sidebar from "./Sidebar";
import {
  book,
  bookmark,
  section_bg_img,
  section_video_img,
  arrow,
} from "../assets";
import { useNavigate, useParams } from "react-router-dom/dist";
import { getCourseContent } from "../_request";
import ReactPlayer from "react-player/lib";

const Section = () => {
  const [courseContent, setCourseContent] = useState({});
  const { id } = useParams();
  const [currentCustomDataIndex, setCurrentCustomDataIndex] = useState(0);
  const currentData = courseContent.customLevels && courseContent.customLevels[currentCustomDataIndex];

  var body = { level_id: id };
  useEffect(() => {
    getCourseContent(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCourseContent(resp?.data);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, []);


  const handleNextModule = () => {
    debugger;
    // Check if there is a "next" module available
    if (courseContent.customLevels && currentData && currentData.next !== null) {
      setCurrentCustomDataIndex(currentCustomDataIndex + 1);
    }
  };
  console.log("courseContentcourseContent", currentData);

  return (
    <section style={{ display: "flex", overflowX: "hidden" }}>
      <section className="sections-sidebar">
        <Sidebar />
      </section>
      <section className="sections-section">
        <div>
          <div className="sections-bg-img">
            <img src={section_bg_img} alt="" loading="lazy" />
          </div>
          <div className="sections-container">
            <div className="sections-heading">
              {courseContent?.course_name}
              {" >"} {courseContent?.name}
            </div>
            <div className="sections-progress">Section {currentCustomDataIndex + 1}/{courseContent?.customLevels?.length}</div>
            <div className="sections-name">
              <h2>{currentData?.name}</h2>
            </div>
            <div className="sections-desc">
              Welcome to UWC! Noemi Vigano, one of the clinical experts who
              developed your program, is here to help you get started.This
              program is based on Cognitive Behavioral Therapy, or CBT for
              short. CBT allows you to become more aware of how you are feeling.
              It also helps you to understand why you might be feeling a certain
              way and teaches you how to make the changes you need, to feel
              better.
            </div>
            <div className="sections-video-img">
              {/* <img src={section_video_img} alt="" loading="lazy" /> */}
              <ReactPlayer
                url={currentData?.video?.playerEmbedUrl}
                controls={true}
              >
              </ReactPlayer>
            </div>

            <div className="sections-footer">
              <div className="sections-transcript">
                <img
                  src={book}
                  alt=""
                  loading="lazy"
                  className="transcript-img"
                />
                <div className="transcript-text">
                  <p>Read Transcript</p>
                  <div className="transcript-line"></div>
                </div>
              </div>
              <div>
                <img
                  src={bookmark}
                  alt=""
                  loading="lazy"
                  className="transcript-bookmark"
                />
              </div>
            </div>
            {currentData?.next &&
              <div className="sections-btn" style={{ cursor: 'pointer' }} onClick={handleNextModule}>
                <div className="modules-btn-text">Next Module</div>
                <div className="arrow">
                  <img src={arrow} alt="" loading="lazy" className="arrow-img" />
                </div>
              </div>
            }
          </div>
        </div>
      </section>
    </section>
  );
};

export default Section;
