import React, { useEffect, useRef, useState } from "react";
import CourseCard from "./CourseCard";
import { arrow_left, arrow_right, course_card } from "./assets";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";

// Swiper.use([Pagination, Navigation]);

const data = [
  {
    title: "Stress Management",
    image: course_card,
  },
  {
    title: "Stress Management",
    image: course_card,
  },
  {
    title: "Stress Management",
    image: course_card,
  },
  {
    title: "Stress Management",
    image: course_card,
  },
  {
    title: "Stress Management",
    image: course_card,
  },
  {
    title: "Stress Management",
    image: course_card,
  },
];

const RelatedCourses = (props) => {
  const { courseData } = props;
  // const owlRef = useRef(null);

  // useEffect(() => {
  //   const options = {
  //     items: 3,
  //     loop: true,
  //     nav: true,
  //     dots: false,
  //     responsive: {
  //       0: {
  //         items: 1,
  //       },
  //       600: {
  //         items: 2,
  //       },
  //       1000: {
  //         items: 3,
  //       },
  //     },
  //   };

  //   // Initialize Owl Carousel
  //   const owl = $(owlRef.current).owlCarousel(options);

  //   // Cleanup when the component unmounts
  //   return () => {
  //     owl.trigger("destroy.owl.carousel").removeClass("owl-loaded");
  //     owl.find(".owl-stage-outer").children().unwrap();
  //   };
  // }, []);

  return (
    <section className="related-course-section">
      <div className="related-course-container">
        <div className="related-course-heading">Related Courses</div>
        <div className="related-course-desc">
          Connect with our community and stay inspired on your mental wellness
          journey.
        </div>

        <div className="related-course-cards">
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            spaceBetween={30}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
          >
            <div className="related-course-card">
              {courseData?.related_courses?.map((item, i) => (
                <SwiperSlide key={i}>
                  <CourseCard
                    item={item}
                    courseDataURL={courseData?.course_url}
                  />
                </SwiperSlide>
              ))}
            </div>
            <div>
              <div className="swiper-button-next custom-arrow"></div>
              <div className="swiper-button-prev custom-arrow"></div>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default RelatedCourses;
