import React, { useContext, useEffect, useState } from 'react'
import './Plans.css'
import "./PlanDetailsPage.css"  //../../pages/PlanDetailsPage.css
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import OwlCarousel from 'react-owl-carousel';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../../auth';
import { AppContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import PlanDetailsReviewSlide from '../planDetails/PlanDetailsReviewSlide';
import TopNavArea from './Components/TopNavArea';
import NavArea from './Components/Nav';
import HeroArea from './Components/HeroArea';
import PersonalDataArea from './Components/PersonalDataArea';
import PickUpPlans from './Components/PickUpPlans';
import MakeUsArea from './Components/MakeUsArea';
import ComparePlans from './Components/ComparePlans';
import ChoiceArea from './Components/ChoiceArea';
import TrustArea from './Components/TrustArea';
import ReasonArea from './Components/ReasonArea';
import PartnerArea from './Components/PartnerArea';
import ClientArea from './Components/ClientArea';
import BestArea from './Components/BestArea';
import ReviewArea from './Components/ReviewArea';
import TeamArea from './Components/TeamArea';
import FaqArea from './Components/FaqArea';
import FloatingWhatsapp from './Components/FloatingWhatsapp';
import { Helmet } from 'react-helmet';
import { getCountryCode } from '../core/commonFun';
import ComparePlanOutsideIndia from './Components/ComparePlanOutsideIndia';



const NewPlansPage1 = () => {

    const [isCountryIndia, setIsCountryIndia] = useState();

    var countryCode = getCountryCode();
    useEffect(() => {
        if (countryCode === "IN") {
            setIsCountryIndia(true)
        } else {
            setIsCountryIndia(false)
        }
    }, []);

    return (
        <div className="main bg-white new-plans">
            <Helmet>
                <script src="//code.tidio.co/y84keavfozv8pkn1ar3c4h0cofpeq7le.js" async></script>
            </Helmet>
            {/* <!------ top-nav-area ------> */}
            <TopNavArea />
            {/* <!------ nav-area (desktop-display)------> */}

            {/* <!------ nav-area ------> */}
            {/* <NavArea /> */}

            {/* <!------ Hero-area ------> */}
            <HeroArea />

            {/* <!------ personal-data-area ------> */}
            <PersonalDataArea />

            {/* <!------ pick-plan-area ------> */}
            <PickUpPlans />


            {/* <!------ Compare-plan-area ------> */}
            {isCountryIndia ?
                <ComparePlans /> :
                <ComparePlanOutsideIndia />
            }

            {/* <!------ Make-Us-area ------> */}
            <MakeUsArea />

            {/* <!------ choice-area ------> */}
            <ChoiceArea />

            {/* <!------ trust-area ------> */}
            <TrustArea />

            {/* <!------ reason-area ------> */}
            <ReasonArea />

            {/* <!------ partner-area ------> */}
            <PartnerArea />

            {/* <!------ client-area ------> */}
            <ClientArea />

            {/* <!------ best-area ------> */}
            <BestArea />

            {/* <!------ review-area ------> */}
            <ReviewArea />

            {/* <!------ team-area ------> */}
            <TeamArea />

            {/* <!------ FAQ-area ------> */}
            <FaqArea />

            {/* <!------ Testimonial-area ------> */}
            <div style={{ clear: 'both', marginBottom:"-10px" }}>
                <PlanDetailsReviewSlide />
            </div>

            {/* floating-WhatsApp */}
            {/* <FloatingWhatsapp /> */}
        </div >
    )
}

export default NewPlansPage1;