import React, { useEffect, useState } from 'react'
import '.././Plans.css'
import { useAuth } from '../../../auth';
import { useContext } from 'react';
import { AppContext } from '../../../context';
import { useNavigate } from 'react-router-dom';
import { getCountryCode } from '../../core/commonFun';

const PickUpPlans = () => {

    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const navigate = useNavigate();
    const [isCountryIndia, setIsCountryIndia] = useState();

    var countryCode = getCountryCode();
    console.log("countryCode", countryCode)
    useEffect(() => {
        if (countryCode === "IN") {
            setIsCountryIndia(true)
        } else {
            setIsCountryIndia(false)
        }
    }, [])

    const onFree = () => {
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", '/stella')
            setShowLloginPopup(true);
        }
        else {
            return navigate('/stella');
        }
    }

    const onPaid = (item) => {
        sessionStorage.setItem("subscriptionID", item);
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", `/subscription-order-summary`)
            setShowLloginPopup(true);
        }
        else {
            navigate(`/subscription-order-summary`)
        }
    }

    const onLearnMore = (item) => {
        if (item === 5) {
            navigate('/platinum-care-plan')
        } else if (item === 7) {
            navigate('/premium-care-plan')
        } else if (item === 6) {
            navigate('/super-care-plan')
        }
    }

    return (
        <div>
            {/* <!------ pick-plan-area ------> */}
            <div id='Subscribe_Now' className="pick-plan-area">
                <div className="">
                    <h2 className="heading mb-md-4">Pick Your<span className="diff-clr"> Plan</span></h2>
                    <div className="pick-plan-inner">
                        <div className="row justify-content-center px-0 mx-0" style={{ float: "left" }}>
                            <div className="col-lg-4 col-xxl-3 px-md-3">
                                <div className="plan-bg m-t">
                                    <div className="plan h-100 d-flex flex-column justify-content-around">
                                        <div>
                                            <h3>Care</h3>
                                            <h6>Available to you 24x7!</h6>
                                            <p><span className="price">
                                                {isCountryIndia ?
                                                    "INR 0" :
                                                    "$0"
                                                }
                                            </span> /month</p>
                                            <hr style={{ borderTop: "2px solid #027A487D" }} />
                                            <h4>Benefits</h4>
                                            <ul>
                                                <li>
                                                    <img src="assets/img/check-bx.png" />
                                                    <h5>Chat with STELLA</h5>
                                                    <p>Unlock self-improvement potential with Stella's comprehensive assessment</p>
                                                </li>
                                                <li>
                                                    <img src="assets/img/check-bx.png" />
                                                    <h5>Basic Wellness Courses</h5>
                                                    <p>Basic Wellness Courses to elevate your
                                                        well-being</p>
                                                </li>
                                                <li>
                                                    <img src="assets/img/check-bx.png" />
                                                    <h5>Exclusive enriching content</h5>
                                                    <p>Videos, audio, and blogs</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="plan-btn">
                                            <button type="button" onClick={onFree} className="btn border-0"><a href="#">Start Now</a></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xxl-3 px-md-3">
                                <div className="plan-bg1">
                                    <div className="plan1 d-flex flex-column justify-content-between h-100 pb-5">
                                        <div>
                                            <h3>Premium Care</h3>
                                            <h6>A plan that actually takes CARE of you</h6>
                                            <p><span className="price">
                                                {isCountryIndia ?
                                                    "INR 999" :
                                                    "$12.99"
                                                }
                                            </span> /month</p>
                                            {/* <div className="seller-area">
                                            <img src="assets/img/Best Seller.png" />
                                            <p>Best Seller</p>
                                        </div> */}
                                            <hr />
                                            <h4>Benefits</h4>
                                            <ul>
                                                <li>
                                                    <img src="assets/img/check-bx.png" />
                                                    <h5>Chat with STELLA</h5>
                                                    <p>Unlock self-improvement potential with Stella's comprehensive assessment</p>
                                                </li>
                                                <li>
                                                    <img src="assets/img/check-bx.png" />
                                                    <h5>Premium Courses</h5>
                                                    <p>Exclusive access to courses aimed at
                                                        enhancing your mental wellness</p>
                                                </li>
                                                <li>
                                                    <img src="assets/img/check-bx.png" />
                                                    <h5>Exclusive enriching content</h5>
                                                    <p>Videos, audio, and blogs</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="plan1-btn">
                                                    <button type="button" onClick={() => {isCountryIndia ? onPaid(7) : onPaid(9)}} className="btn col-12 px-2 border-0"><a href="#" style={{ whiteSpace: 'nowrap' }}>Subscribe Now</a></button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="plan2-btn">
                                                    <button type="button" onClick={() => onLearnMore(7)} className="btn col-12 px-2" style={{ whiteSpace: "nowrap" }}><a href="#">Learn More</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="plan1-para">
                                    <p>And the best part, our plans come with 7 days cooling-off period </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xxl-3 px-md-3">
                                <div className="plan-bg2 m-t">
                                    <div className="plan1 d-flex flex-column justify-content-between h-100">
                                        <div>
                                            <h3>Super Care</h3>
                                            <h6>The only mental health subscription<br /> you will
                                                need</h6>
                                            <p><span className="price">
                                                {isCountryIndia ?
                                                    "INR 2999" :
                                                    "$38"
                                                }
                                            </span>/month</p>
                                            {/* <div className="seller-area m-t-n">
                                            <img src="assets/img/Medal.png" />
                                            <p>Best Seller</p>
                                        </div> */}
                                            <hr />
                                            <h4>Benefits</h4>
                                            <ul>
                                                <li>
                                                    <img src="assets/img/check-bx.png" />
                                                    <h5>Chat with STELLA</h5>
                                                    <p>Unlock self-improvement potential with Stella's comprehensive assessment</p>
                                                </li>
                                                <li>
                                                    <img src="assets/img/check-bx.png" />
                                                    <h5>Premium Courses</h5>
                                                    <p>Exclusive access to courses aimed at
                                                        enhancing your mental wellness</p>
                                                </li>
                                                <li>
                                                    <img src="assets/img/check-bx.png" />
                                                    <h5>Exclusive enriching content</h5>
                                                    <p>Videos, audio, and blogs</p>
                                                </li>
                                                <li>
                                                    <img src="assets/img/check-bx.png" />
                                                    <h5>Customized care plans </h5>
                                                    <p>Designed specifically to meet your needs</p>
                                                </li>
                                                <li>
                                                    <img src="assets/img/check-bx.png" />
                                                    <h5>Personalised 3 Hour sessions</h5>
                                                    <p>3 hours live session with experts</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="plan1-btn">
                                                    <button type="button" onClick={() => { isCountryIndia ? onPaid(6) : onPaid(10)}} className="btn col-12 new border-0 px-2"><a href="#" style={{ whiteSpace: 'nowrap' }}>Subscribe Now</a></button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="plan2-btn">
                                                    <button type="button" onClick={() => onLearnMore(6)} className="btn col-12 new" style={{ whiteSpace: "nowrap" }}><a href="#">Learn More</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isCountryIndia ?
                                <div className="col-lg-4 col-xxl-3 px-md-3">
                                    <div className="plan-bg3 m-t">
                                        <div className="plan1 d-flex flex-column justify-content-between h-100">
                                            <div>
                                                <h3 className='text-black'>Platinum Care</h3>
                                                <h6 className='text-black me-4'>The mental health subscription for all your needs
                                                    will need</h6>
                                                <p className='text-black'><span className="price">INR 9999</span> /month</p>
                                                {/* <div className="seller-area m-t-n">
                                            <img src="assets/img/Medal.png" />
                                            <p>Best Seller</p>
                                        </div> */}
                                                <hr style={{ borderTop: "2px solid #000000" }} />
                                                <h4 className='text-black'>Benefits</h4>
                                                <ul>
                                                    <li>
                                                        <img src="assets/img/check-bx.png" />
                                                        <h5 className='text-black'>Chat with STELLA</h5>
                                                        <p className='text-black'>Unlock self-improvement potential with Stella's comprehensive assessment</p>
                                                    </li>
                                                    <li>
                                                        <img src="assets/img/check-bx.png" />
                                                        <h5 className='text-black'>Premium Courses</h5>
                                                        <p className='text-black'>Exclusive access to courses aimed at
                                                            enhancing your mental wellness</p>
                                                    </li>
                                                    <li>
                                                        <img src="assets/img/check-bx.png" />
                                                        <h5 className='text-black'>Exclusive enriching content</h5>
                                                        <p className='text-black'>Videos, audio, and blogs</p>
                                                    </li>
                                                    <li>
                                                        <img src="assets/img/check-bx.png" />
                                                        <h5 className='text-black'>Customized care plans </h5>
                                                        <p className='text-black'>Designed specifically to meet your needs</p>
                                                    </li>
                                                    <li>
                                                        <img src="assets/img/check-bx.png" />
                                                        <h5 className='text-black'>Personalised 1-1 sessions</h5>
                                                        <p className='text-black'>Unlimited live sessions with professional experts</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <div className="plan1-btn">
                                                        <button type="button" onClick={() => onPaid(5)} className="btn col-12 new border-0"><a href="#" style={{ whiteSpace: 'nowrap' }}>Subscribe Now</a></button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <div className="plan2-btn">
                                                        <button type="button" onClick={() => onLearnMore(5)} className="btn col-12 new" style={{ borderColor: "#000", whiteSpace: "nowrap" }}><a href="#" className='text-black'>Learn More</a></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PickUpPlans