import React, { useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/TalkToStella.css";
import {
  section_bg_img,
  bookmark,
  left_arrow,
  arrow,
  stella,
  info_icon,
} from "../assets";
import Popup from "./Modal";

const modal_data = [
  {
    title: "Your personal journal",
    desc: "Your journal is one of the most important tools in this program.",
    subHead: "There are two different ways in which you can use your journal:",
    step1:
      "1. Standard journal entries, Simply type in whatever you like as free text below.",
    step2:
      "2. Journal entries linked to the program, You might like to use your journal to reflect on things you notice and learn as you go through the program content, using the 'Comment in your journal' button which sits below each block of information.",
  },
];

const TalkToStella = () => {
  const [showModal, setShowModal] = useState(false);
  // const openModal = () => setShowModal(true);
  // const closeModal = () => setShowModal(false);

  return (
    <section className="stella">
      <section className="stella-sidebar">
        <Sidebar />
      </section>
      <section className="stella-section">
        <div className="stella-bg-img">
          <img src={section_bg_img} alt="" loading="lazy" />
        </div>
        <div className="stella-container">
          <div className="sections-heading">
            Space from Depression and Anxiety
            {" > "} Getting Started
          </div>
          <div className="white-layout">
            <div className="stella-sections-progress">Section 2/6</div>
            <div className="quiz-sections-name">
              <div styles={{ display: "flex", gap: "1rem" }}>
                Start questionnaire with STELLA!
                <img
                  src={info_icon}
                  alt=""
                  loading="lazy"
                  className="quiz-info-icon"
                  onClick={() => setShowModal(true)}
                />
                {/* {showModal && ( */}
                <Popup
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  data={modal_data[0]}
                />
                {/* )} */}
              </div>

              <div>
                <img
                  src={bookmark}
                  alt=""
                  loading="lazy"
                  className="quiz-bookmark"
                />
              </div>
            </div>
          </div>

          <div className="talk-to-stella-container">
            <div className="stella-chat">
              <img src={stella} alt="" loading="lazy" className="stella-img" />
              <p className="stella-text">Hi! How is your day?</p>
            </div>

            <div className="user-chat">I am good</div>

            <div className="stella-chat">
              <img src={stella} alt="" loading="lazy" className="stella-img" />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p className="stella-text">I am so happy in that case.</p>
                <p className="stella-text">
                  A moment of GRATITUDE makes a difference in your ATTITUDE"
                  -Bruce Wlikinson
                </p>
              </div>
            </div>

            <div className="user-chat">I am not able to sleep</div>

            <div className="stella-chat">
              <img src={stella} alt="" loading="lazy" className="stella-img" />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p className="stella-text">
                  What kind of videos do you like to watch?
                  <div className="stella-options">
                    <p className="stella-option">I wish to sleep</p>
                    <p className="stella-option">I wish to journal</p>
                    <p className="stella-option">Show me sleep stories</p>
                  </div>
                </p>
              </div>
            </div>

            <div className="user-chat">Suggest me some videos</div>
          </div>

          <div className="footer-btn">
            <div className="prev-btn">
              <div className="modules-prev-text">Previous Section</div>
              <div className="prev-arrow">
                <img
                  src={left_arrow}
                  alt=""
                  loading="lazy"
                  className="arrow-img"
                />
              </div>
            </div>
            <div className="next-btn">
              <div className="modules-text">Next Section</div>
              <div className="next-arrow">
                <img src={arrow} alt="" loading="lazy" className="arrow-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default TalkToStella;
