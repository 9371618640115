import React from 'react'
import '.././Plans.css'

const TeamArea = () => {
    return (
        <div>
            {/* <!------ team-area ------> */}
            <div className="team-area">
                <h2 className="heading m-dis mb-5 px-4 px-md-0"> Talk to<span className="diff-clr"> 100+ Renowned Experts</span> and <br className='d-none d-md-block' />
                    mental health professionals, globally </h2>
                <div className="our-team">
                    <img src="assets/img/MansiChawla.png" className='px-1' alt="" />
                    <div className="team-content">
                        <h3 className="post-title">Mansi Chawla</h3>
                        <p className="description">
                            10+ years of Experience<br />
                            <i> Psychologist</i>
                            <br />
                            <br />
                            “I have more than ten years of extensive experience dealing with patients of all ages in private practise as a compassionate psychotherapist.”
                        </p>
                    </div>
                    <div className="team-prof">
                        <h3 className="post-title">Mansi Chawla<img src="assets/img/team-v.png" alt="" /></h3>
                        <span className="post"><img src="assets/img/planDetails/calender.png" alt="" />Available Today</span>
                    </div>
                </div>
                <div className="our-team">
                    <img src="assets/img/SapnaZarwal.png" className='px-1' alt="" />
                    <div className="team-content">
                        <h3 className="post-title">Sapna Zarwal</h3>
                        <p className="description">
                            19+ years of Experience<br />
                            <i> Psychologist</i>
                            <br />
                            <br />
                            “A Former Head of Psychology services, School Director, renowned Mental Health professional with a pace to make a difference in the field of Mental Health for Adults and Children .”
                        </p>
                    </div>
                    <div className="team-prof">
                        <h3 className="post-title">Sapna Zarwal<img src="assets/img/team-v.png" alt="" /></h3>
                        <span className="post"><img src="assets/img/planDetails/calender.png" alt="" />Available Today</span>
                    </div>
                </div>
                <div className="our-team">
                    <img src="assets/img/KirtiBajpai.png" className='px-1' alt="" />
                    <div className="team-content">
                        <h3 className="post-title">Kirti Bajpai</h3>
                        <p className="description">
                            20+ years of Experience<br />
                            <i> Psychologist</i>
                            <br />
                            <br />
                            “I am a counselling psychologist with a working experience of more than 20 years. My clients are from all age groups, ranging from 11 years old to 75 years old.”
                        </p>
                    </div>
                    <div className="team-prof">
                        <h3 className="post-title">Kirti Bajpai<img src="assets/img/team-v.png" alt='' /></h3>
                        <span className="post"><img src="assets/img/planDetails/calender.png" alt='' />Available Today</span>
                    </div>
                </div>
                <div className="our-team">
                    <img src="assets/img/SeemaTaneja.png" className='px-1' alt="" />
                    <div className="team-content">
                        <h3 className="post-title">Seema Taneja</h3>
                        <p className="description">
                            30+ years of Experience<br />
                            <i>Psychologist</i>
                            <br />
                            <br />
                            “I have always desired a career in the field of mental health and its impact on people's lives. To make mental health accessible to all, I operate a non-profit organization”
                        </p>
                    </div>
                    <div className="team-prof">
                        <h3 className="post-title">Seema Taneja<img src="assets/img/team-v.png" alt='' /></h3>
                        <span className="post"><img src="assets/img/planDetails/calender.png" alt='' />Available Today</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamArea