import React, { useEffect, useState } from 'react'
import Iframe from 'react-iframe'
import { Button, Col, Collapse, Container, Nav, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { courseData } from '../core/models'
import { getCountryCode, getCurrencyCode } from '../../core/commonFun'
import 'swiper/css';
import "swiper/css/navigation";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { isAccordionItemSelected } from 'react-bootstrap/esm/AccordionContext';
import { OODOCoursePaymentService, course_clubs, get_course_details_web_by_Id } from '../../core/_request';
import { useContext } from 'react';
import { AppContext } from '../../../context';
import { useAuth } from '../../../auth';
import { Helmet } from 'react-helmet';

const CourseDetailID1 = (props) => {
    const { id } = useParams();
    const data = courseData?.find(o => o.id == id)
    const countryCode = getCountryCode();
    const CurrencyCode = getCurrencyCode();
    const [readMoreDescriptionText, setReadMoreDescriptionText] = useState();
    const [readMoreWhyTakeCourseText, setReadMoreWhyTakeCourseText] = useState();
    const [sectionRefs, setSectionRefs] = useState([]);
    const [structurEventKey, setStructurEventKey] = useState(0);
    const [navIndex, setNavIndex] = useState(1);
    const [course_details, setCourse_details] = useState();
    const navigate = useNavigate();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const [headerClubsData, setHeaderClubsData] = useState([]);
    const { currentUser } = useAuth();
    console.log("languagesdatadata", data);
    var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
    useEffect(() => {
        var body = { userId: user_id }
        get_course_details_web_by_Id(id, body).then(resp => {
            setCourse_details(resp?.data)
        });

        setSectionRefs(
            Array.from(document.querySelectorAll("section")).map((ref) => {
                return { id: ref.id, top: ref.offsetTop };
            })
        );

        // course_clubs().then(resp => {
        //     setHeaderClubsData(resp?.data?.clubs);
        //     console.log("setCourse_details", resp?.data?.clubs)
        // });
    }, []);

    console.log("course_details", course_details)

    const onEnrollNow = () => {
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", `course/details/${id}`)
            setShowLloginPopup(true);
        }
        else {
            if (course_details?.course_type === "free") {
                debugger
                var requestt = { userId: user_id, courseId: id };
                OODOCoursePaymentService(requestt).then(_ => navigate("/dashboard/course/my-courses"));;
            }
            else if (course_details?.course_type === "premium") {
                if (course_details?.inr_price === 0) {
                    var requestt = { userId: user_id, courseId: id };
                    OODOCoursePaymentService(requestt).then(_ => navigate("/dashboard/course/my-courses"));
                } else if (course_details?.inr_price > 0) {
                    localStorage.setItem("retrunurl", `course/details/${id}`);
                    navigate('/plans');
                }
            }
        }
    }


    // const handleIndexClick = (index) => {
    //     const section = sectionRefs[index];
    //     document.getElementById(section.id).scrollIntoView({ behavior: "smooth" });
    // };



    return (
        <div>
            <Helmet>
                <title>{course_details?.name + " - United We Care"}</title>
            </Helmet>
            <Container>
                <div className='row p-md-3'>
                    <div className='col-md-6 d-flex flex-column justify-content-start'>
                        <div className='d-flex align-items-center justify-content-between px-2 my-2 rounded-2'>
                            <div className='row align-items-center gap-1'>
                                <p className='font-inter w-auto rounded-2' style={{ fontSize: "14px", color: "#0E0F0C", background: "#EEF9FF" }}>
                                    {course_details?.club?.name}
                                </p>
                            </div>
                        </div>
                        <h1 className='font-inter fs-5 pb-2 fs-3 font-w600 my-md-2 lh'>
                            {course_details?.name}
                        </h1>
                        <div className='col-md-6 d-block d-md-none'>
                            <Iframe
                                src={course_details?.video?.video?.player_embed_url}
                                width='100%'
                                height='230px'
                                className='border-2'
                                // url='https://embed-ssl.wistia.com/deliveries/67691ab6f4d0a9da7c09dd950ae2961f940c5a85.webp?image_crop_resized=1920x1080'
                                styles={{ borderRadius: "10px" }}>
                            </Iframe>
                        </div>
                        <div className='d-flex gap-3 py-2'>
                            <div className='d-flex gap-2 align-items-center justify-content-start'>
                                <img src='/assets/img/Mask group(10).png' alt='' />
                                <p className='font-inter font-w500 fs-6' style={{ color: "#0E0F0C" }}>{course_details?.duration}</p>
                            </div>
                            <div className='d-flex gap-2 align-items-center justify-content-start'>
                                <img src='/assets/img/image 14(1).png' alt='' />
                                <p className='font-inter font-w500 fs-6' style={{ color: "#0E0F0C" }}>{course_details?.rating}</p>
                            </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center justify-content-start pb-2'>
                            <img src='/assets/img/Mask group(11).png' alt='' />
                            <p className='font-inter font-w500 fs-6 lh-lg' style={{ color: "#0E0F0C" }}>{course_details?.languages}</p>
                        </div>
                    </div>
                    <div className='col-md-6 d-none d-md-block'>
                        <Iframe
                            src={course_details?.video?.video?.player_embed_url}
                            width='100%'
                            height='300px'
                            className='border-2'
                            // url='https://embed-ssl.wistia.com/deliveries/67691ab6f4d0a9da7c09dd950ae2961f940c5a85.webp?image_crop_resized=1920x1080'
                            styles={{ borderRadius: "10px" }}>
                        </Iframe>
                    </div>
                </div>
            </Container>
            <div>
                <div>
                    <div className='py-3 sticky-top p-2' style={{ backgroundColor: "#EEF9FF" }}>
                        <nav className='col-md-6 mx-auto d-flex hideScrollbar gap-3' style={{ overflowX: 'scroll', whiteSpace: "nowrap" }}>
                            <ul className='d-flex list-unstyled gap-3 justify-content-center'>
                                {course_details?.overview &&
                                    <li
                                        className={navIndex == "1" ? 'font-inter text-white bg-black rounded-5' : 'font-inter text-black bg-white rounded-5'}
                                        style={{ background: "white" }}>
                                        <a className='text-decoration-none font-inter' style={{ fontSize: 14, fontWeight: '600' }} href="#section1" onClick={() => setNavIndex(1)}>
                                            <h2 style={{ color: navIndex == "1" ? "white" : "black" }} className="m-0 fs-6 font-w600 lh-1 font-inter px-3 py-2">OVERVIEW</h2>
                                        </a>
                                    </li>
                                }
                                {course_details?.advantages &&
                                    <li className={navIndex == "2" ? 'font-inter text-white bg-black rounded-5' : 'font-inter text-black bg-white rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600' }} href="#section2" onClick={() => setNavIndex(2)}>
                                            <h2 style={{ color: navIndex == "2" ? "white" : "black" }} className="m-0 fs-6 font-w600 lh-1 font-inter px-3 py-2">ADVANTAGES</h2>
                                        </a>
                                    </li>
                                }
                                {course_details?.inclusions &&
                                    <li className={navIndex == "3" ? 'font-inter text-white bg-black rounded-5' : 'font-inter text-black bg-white rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600' }} href="#section3" onClick={() => setNavIndex(3)}>
                                            <h2 style={{ color: navIndex == "3" ? "white" : "black" }} className="m-0 fs-6 font-w600 lh-1 font-inter px-3 py-2">INCLUSIONS</h2>
                                        </a>
                                    </li>
                                }
                                {course_details?.requirements &&
                                    <li className={navIndex == "4" ? 'font-inter text-white bg-black rounded-5' : 'font-inter text-black bg-white rounded-5'}>
                                        <a className='text-decoration-none font-inter' style={{ fontSize: 14, fontWeight: '600' }} href="#section4" onClick={() => setNavIndex(4)}>
                                            <h2 style={{ color: navIndex == "4" ? "white" : "black" }} className="m-0 fs-6 font-w600 lh-1 font-inter px-3 py-2">REQUIREMENTS</h2>
                                        </a>
                                    </li>
                                }
                                {course_details?.success_rate &&
                                    <li className={navIndex == "5" ? 'font-inter text-white bg-black rounded-5' : 'font-inter text-black bg-white rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600' }} href="#section5" onClick={() => setNavIndex(5)}>
                                            <h2 style={{ color: navIndex == "5" ? "white" : "black" }} className="m-0 fs-6 font-w600 lh-1 font-inter px-3 py-2">SUCCESS RATE</h2>
                                        </a>
                                    </li>
                                }
                                {course_details?.modules?.length != 0 &&
                                    <li className={navIndex == "6" ? 'font-inter text-white bg-black rounded-5' : 'font-inter text-black bg-white rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600' }} href="#section6" onClick={() => setNavIndex(6)}>
                                            <h2 style={{ color: navIndex == "6" ? "white" : "black" }} className="m-0 fs-6 font-w600 lh-1 font-inter px-3 py-2">MODULES</h2>
                                        </a>
                                    </li>
                                }
                                {course_details?.experts?.length != 0 &&
                                    <li className={navIndex == "7" ? 'font-inter text-white bg-black rounded-5' : 'font-inter text-black bg-white rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600' }} href="#section7" onClick={() => setNavIndex(7)}>
                                            <h2 style={{ color: navIndex == "7" ? "white" : "black" }} className="m-0 fs-6 font-w600 lh-1 font-inter px-3 py-2">EXPERTS</h2>
                                        </a>
                                    </li>
                                }
                                {course_details?.reviews?.length != 0 &&
                                    <li className={navIndex == "8" ? 'font-inter text-white bg-black rounded-5' : 'font-inter text-black bg-white rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600' }} href="#section8" onClick={() => setNavIndex(8)}>
                                            <h2 style={{ color: navIndex == "8" ? "white" : "black" }} className="m-0 fs-6 font-w600 lh-1 font-inter px-3 py-2">REVIEWS</h2>
                                        </a>
                                    </li>
                                }
                                {course_details?.need_more_help &&
                                    <li className={navIndex == "9" ? 'font-inter text-white bg-black rounded-5' : 'font-inter text-black bg-white rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600' }} href="#section9" onClick={() => setNavIndex(9)}>
                                            <h2 style={{ color: navIndex == "9" ? "white" : "black" }} className="m-0 fs-6 font-w600 lh-1 font-inter px-3 py-2">NEED MORE HELP</h2>
                                        </a>
                                    </li>
                                }
                            </ul>
                        </nav>
                    </div>
                    <div className='col-md-6   mx-auto'>
                        {course_details?.overview &&
                            <section id="section1" className='pt-3'>
                                <div className='my-1 p-3'>
                                    <h2 className='font-w600 font-inter fs-5 text-black lh-lg'>Overview</h2>
                                    <p className='font-inter fs-6' style={{ color: "#21231E" }}>
                                        {course_details?.overview && course_details?.overview}
                                    </p>
                                </div>
                            </section>
                        }
                        {course_details?.advantages &&
                            <section id="section2" className='pt-3'>
                                {course_details?.advantages && <div className='my-0 p-3'>
                                    <h2 className='font-w600 font-inter fs-5 text-black lh-lg'>Advantages</h2>
                                    <div className='font-inter fs-6' style={{ color: "#21231E" }}>
                                        <div dangerouslySetInnerHTML={{ __html: course_details?.advantages }} ></div>
                                    </div>
                                </div>}
                            </section>
                        }
                        {course_details?.inclusions &&
                            <section id="section3" className='pt-3'>
                                <div className='my-0 p-3'>
                                    <h2 className='font-w600 font-inter fs-5 text-black lh-lg'>Inclusions</h2>
                                    <div className='font-inter fs-6 p-3' style={{ color: "#21231E" }}>
                                        <div dangerouslySetInnerHTML={{ __html: course_details?.inclusions }} ></div>
                                    </div>
                                </div>
                            </section>
                        }
                        {course_details?.requirements &&
                            <section id="section4" className='pt-3'>
                                <div className='my-0 p-3'>
                                    <h2 className='font-w600 font-inter fs-5 text-black lh-lg'>Requirements</h2>
                                    <div className='font-inter fs-6' style={{ color: "#21231E" }}>
                                        <div dangerouslySetInnerHTML={{ __html: course_details?.requirements }} ></div>
                                    </div>
                                </div>
                            </section>
                        }
                        {course_details?.success_rate &&
                            <section id="section5" className='pt-3'>
                                <div className='my-0 p-3'>
                                    <h2 className='font-w600 font-inter fs-5 text-black lh-lg'>Success Rate</h2>
                                    <div className='font-inter fs-6' style={{ color: "#21231E" }}>
                                        <div dangerouslySetInnerHTML={{ __html: course_details?.success_rate }} ></div>
                                    </div>
                                </div>
                            </section>
                        }
                        {course_details?.modules?.length != 0 &&
                            <section id="section6" className='pt-3'>
                                <div className='my-0 p-3'>
                                    <h3 className='font-w600 font-inter fs-5 text-black lh-lg'>Modules</h3>
                                    <div>
                                        <div className="row">
                                            <div className="px-0">
                                                <Accordion
                                                    defaultActiveKey={0}
                                                    onSelect={(eventKey) =>
                                                        setStructurEventKey(eventKey)}
                                                >
                                                    <ul className="timeline list-unstyled position-relative ps-4">
                                                        {
                                                            course_details?.modules?.map((item, index) => (
                                                                <li className="timeline-item bg-white rounded ml-3 p-4">
                                                                    <div className="timeline-arrow"></div>
                                                                    <h6 className="font-inter font-w600 fs-6 lh-1 pb-3">{item?.heading}</h6>
                                                                    <div className='rounded-2 p-2 ms-2' style={{ background: "#EEF9FF" }}>
                                                                        {/* <p className='font-inter font-w600 fs-6 text-black lh-lg'>Understanding the Basics of Sleep</p> */}
                                                                        <Accordion.Collapse eventKey={index}>
                                                                            <p className='font-inter fs-6 text-black lh-lg'>
                                                                                <span dangerouslySetInnerHTML={{ __html: item?.body }} ></span>
                                                                            </p>
                                                                        </Accordion.Collapse>
                                                                        <CustomToggle eventKey={index} >
                                                                            {
                                                                                structurEventKey === index &&
                                                                                <p className='font-inter fs-6'>Show less <img src='/assets/img/Icon(2).png' alt='' /></p>
                                                                                ||
                                                                                <p className='font-inter fs-6'>Show more <img src='/assets/img/Icon(3).png' alt='' /></p>
                                                                            }
                                                                        </CustomToggle>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        }
                        {course_details?.experts?.length != 0 &&
                            <section id="section7" className='pt-3'>
                                <div className='my-0 px-3 py-4' style={{ background: "#EEF9FF" }}>
                                    <h3 className='font-inter font-w600 fs-3 text-center lh-lg'>About the expert</h3>
                                    <Swiper
                                        spaceBetween={50}
                                        pagination={true}
                                        // navigation={true}
                                        modules={[Scrollbar, Pagination, Navigation, A11y]}
                                        className="col-md-12">
                                        {course_details?.experts?.map((item, index) => (
                                            <SwiperSlide className='py-5'>
                                                <div>
                                                    <div className='row align-items-center'>
                                                        <div className='col-3' >
                                                            <img src={item?.image} alt='' style={{ width: 150, borderRadius: 10 }} />
                                                        </div>

                                                        <div className='col-9'>
                                                            <p style={{ fontSize: 16, fontFamily: 'Inter', fontWeight: '600', color: '#000000' }}>{item?.name}</p>
                                                            <p style={{ fontSize: 14, fontFamily: 'Inter', fontWeight: '400', color: '#0E0F0C' }}>{item?.type}</p>
                                                            <div className='d-flex gap-2 align-items-center'>
                                                                <img src='/assets/img/flag.png' />
                                                                <p style={{ fontSize: 14, fontFamily: 'Inter', fontWeight: '400', color: '#0E0F0C' }}>India</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='font-inter fs-6 mt-3 w-100'>
                                                        <div className='font-inter' dangerouslySetInnerHTML={{ __html: item?.description.substr(0, 400) }} ></div>

                                                    </div>
                                                    <Button onClick={() => navigate(`/detail/${item?.id}`)} className='text-white font-inter text-center d-block mx-auto mt-3' style={{ background: "#1B8BCF" }}>Book Appointment</Button>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                        }
                                    </Swiper>
                                    {/* <div>
                                        <h5 className='font-inter font-w600 fs-3 text-center lh-lg'>About the expert</h5>
                                        <div className='row align-items-center'>
                                            <div className='col-3' >
                                                <img src={course_details?.experts[0]?.image} alt='' style={{ width: 150, borderRadius: 10 }} />
                                            </div>

                                            <div className='col-9'>
                                                <p style={{ fontSize: 16, fontFamily: 'Inter', fontWeight: '600', color: '#000000' }}>{course_details?.experts[0]?.name}</p>
                                                <p style={{ fontSize: 14, fontFamily: 'Inter', fontWeight: '400', color: '#0E0F0C' }}>{course_details?.experts[0]?.type}</p>
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <img src='/assets/img/flag.png' />
                                                    <p style={{ fontSize: 14, fontFamily: 'Inter', fontWeight: '400', color: '#0E0F0C' }}>India</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='font-inter fs-6 mt-3'>
                                            <span className='font-inter' dangerouslySetInnerHTML={{ __html: course_details?.experts[0]?.description }} ></span>
                                        </p>
                                        <Button onClick={() => navigate(`/detail/${course_details?.experts[0]?.id}`)} className='text-white font-inter text-center d-block mx-auto mt-3' style={{ background: "#1B8BCF" }}>Book Appointment</Button>
                                    </div> */}
                                </div>
                            </section>
                        }
                        {course_details?.reviews?.length != 0 &&
                            <section id="section8" className='pt-3'>
                                <div className='my-0 px-3 py-4'>
                                    <h3 className='font-inter fs-4 text-center lh-lg'>Testimonials</h3>
                                    <Swiper
                                        slidesPerView={2}
                                        pagination={true}

                                        // navigation={true}
                                        modules={[Scrollbar, Pagination, Navigation, A11y]}
                                        className="mySwiper py-3"
                                        breakpoints={{
                                            274: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                            },
                                            844: {
                                                slidesPerView: 2.3,
                                                spaceBetween: 50,
                                            },
                                        }}
                                        style={{
                                            // "--swiper-pagination-color": "#FFBA08",
                                            // "--swiper-pagination-bullet-inactive-color": "#999999",
                                            // "--swiper-pagination-bullet-inactive-opacity": "1",
                                            // "--swiper-pagination-bullet-size": "16px",
                                            "--swiper-pagination-bullet-vertical-gap": "6px"
                                        }}
                                    >
                                        {course_details?.reviews?.map((item, index) => (
                                            <SwiperSlide>
                                                <div className='d-flex flex-column align-items-center' style={{ background: "#EEF9FF", padding: 15, borderRadius: 10, height: 310 }}>
                                                    <p className='font-inter fs-6 text-black' style={{ textAlign: 'justify', height: 270 }}>
                                                        {item?.description.substr(0, 200)} ...
                                                    </p>
                                                    <img src='/assets/img/Group 1000002231.png' alt='' />
                                                    {/* {item?.rating} */}
                                                    <p className='font-inter font-w600 fs-6 text-black'>{item?.user}</p>
                                                    <p className='font-inter fs-6 text-black'>{item?.location}</p>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                        }
                                    </Swiper>
                                </div>
                            </section>
                        }
                        {course_details?.need_more_help &&
                            <section id="section9" className='pt-3'>
                                <div className='my-0 px-3 py-4'>
                                    <h3 className='font-inter font-w600 fs-5 lh-lg'>Need More Help?</h3>
                                    <p className='font-inter fs-6' style={{ color: "#21231E" }}>
                                        {
                                            console.log("courseDetail", course_details) ||
                                            <span dangerouslySetInnerHTML={{ __html: course_details?.need_more_help }}></span>
                                        }
                                    </p>
                                </div>
                            </section>
                        }

                    </div>

                    <div className='sticky-bottom py-3' style={{ backgroundColor: "#fff" }}>
                        <nav className='col-md-6 mx-auto d-flex hideScrollbar gap-3' style={{ overflowX: 'scroll', whiteSpace: "nowrap" }}>
                            {/* {
                                myCourseData && myCourseData?.courses?.find(o => o.course_id === item.id) &&
                                <Card.Link><Button id='course_start' onClick={() => onClick(item)} variant="warning" className='text-white rounded-5 px-4'>Start</Button></Card.Link> ||
                                <Card.Link><Button id='course_enroll' onClick={() => onClickEnroll(item)} variant="warning" className='text-white rounded-5 px-4'>Enroll Now</Button></Card.Link>
                            } */}
                            <Button id='course_detail_enroll' onClick={() => onEnrollNow()} className=' font-inter text-center d-block mx-auto' style={{ background: "#9DEA66", borderWidth: 0, paddingLeft: 50, paddingRight: 50, color: '#000000' }}>
                              
                                {course_details?.enrolled === true && (course_details?.inr_price === 0 ? "Start Now" : "Continue") || "Enroll now"}
                            </Button>
                        </nav>
                    </div>
                </div >
            </div >
        </div >
        //#region 
        // <div className=''>
        //     <div className='py-3' style={{ backgroundColor: "#1D98E3", height: "300px" }}>
        //         <h6 className='text-center text-white pt-5 pb-2 fs-3 font-w600'>
        //             {data?.name}
        //         </h6>
        //         <p className='text-center text-white py-2'>{data.courseDuration}<span className='px-3'>{data.language}</span></p>
        //         <h5 className='text-center text-white py-1'>Program Fee <span style={{ color: "#FFD255" }}>
        //             {
        //                 countryCode === "IN" &&
        //                 <>
        //                     ₹ {data?.price}
        //                 </>
        //                 ||
        //                 <>
        //                     $ {data?.USD_price}
        //                 </>
        //             }
        //         </span></h5>
        //         <div className='d-flex flex-row align-items-center justify-content-center py-4'>
        //             <a href='#down-here'>
        //                 <img src='/assets/img/down.png' alt='' className='animated-arrow-img' />
        //             </a>
        //         </div>
        //     </div>
        //     <div className='container'>
        //         <div className='row align-items-start py-5 gap-5 gap-md-0'>
        //             <div className='col-md-6  px-md-5'>
        //                 <h3 className='font-w600 py-3'>About the course</h3>
        //                 <p className='fs-5 d-none d-md-block' style={{ color: "#667085", textAlign: "justify" }}>
        //                     {data?.description}
        //                 </p>
        //                 {
        //                     readMoreDescriptionText &&
        //                     <p className='fs-5 d-block d-md-none'>
        //                         {data?.description && data?.description?.length > 200 ?
        //                             <>
        //                                 <span>{data?.description}</span>
        //                             </>
        //                             : data?.description
        //                         }
        //                     </p>
        //                     ||
        //                     <p className='fs-5 d-block d-md-none' style={{ color: "#667085", textAlign: "justify" }}>
        //                         {data?.description && data?.description?.length > 200 ?
        //                             <>
        //                                 <span>{data?.description?.toString()?.slice(0, 200)}</span>
        //                                 <button className="border-0 fs-6 px-3 py-1 font-w600" style={{ background: "none", color: "#3DACF0" }} onClick={() => setReadMoreDescriptionText(true)}>Read More...</button>
        //                             </>
        //                             : data?.description
        //                         }
        //                     </p>
        //                 }
        //             </div>
        //             <div className='col-md-6'>
        //                 {/* <iframe src={data.video.video.link} title='Meditation Course Preview.mp4' className='col-12 h-100' ></iframe> */}
        //                 {/* {data.video.video.embed_html} */}
        //                 {/* <div dangerouslySetInnerHTML={{ __html: data?.video?.video?.embed_html }} className='w-100 h-100'></div> */}
        //                 <Iframe
        //                     src={data?.video?.video?.player_embed_url}
        //                     width='100%'
        //                     height='350px'
        //                     className='border-2 rounded-3'
        //                     // url='https://embed-ssl.wistia.com/deliveries/67691ab6f4d0a9da7c09dd950ae2961f940c5a85.webp?image_crop_resized=1920x1080'
        //                     styles={{ border: "1px solid black" }}></Iframe>
        //                 <div className='col-md-12 my-4 px-5 py-3 d-flex align-items-center justify-content-between rounded-4' style={{ backgroundColor: "#000" }}>
        //                     <h5 className='' style={{ color: "#B9BBC1" }}>Program Fees <br /><span className='text-white'>
        //                         {
        //                             countryCode === "IN" &&
        //                             <>
        //                                 ₹ {data?.price}
        //                             </>
        //                             ||
        //                             <>
        //                                 $ {data?.USD_price}
        //                             </>
        //                         }
        //                     </span></h5>
        //                     <Button id='course_detail_enroll' variant='warning' style={{ backgroundColor: "linear-gradient(90deg, #FF9C00 0%, #FFCE50 100%)" }}
        //                         onClick={() => props.checkout()}
        //                     >
        //                         Enroll Now</Button>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <div style={{ backgroundColor: "#F1F9FD" }}>
        //         <div className='container py-5'>
        //             <h4 className='text-center py-3'>Why should you take this course?</h4>
        //             <p className='text-center d-none d-md-block' style={{ color: "#3A3F4B" }}>
        //                 {data?.whyShouldTake}
        //             </p>
        //             {
        //                 readMoreWhyTakeCourseText &&
        //                 <p className='fs-5 d-block d-md-none'>
        //                     {
        //                         data?.whyShouldTake && data?.whyShouldTake?.length > 200 ?
        //                             <>
        //                                 <span>{data?.whyShouldTake}</span>
        //                             </>
        //                             : data?.whyShouldTake
        //                     }
        //                 </p>
        //                 ||
        //                 <p className='fs-5 d-block d-md-none' style={{ color: "#667085", textAlign: "justify" }}>
        //                     {data?.whyShouldTake && data?.whyShouldTake?.length > 200 ?
        //                         <>
        //                             <span>{data?.whyShouldTake?.toString()?.slice(0, 200)}</span>
        //                             <button className="border-0 fs-6 px-3 py-1 font-w600" style={{ background: "none", color: "#3DACF0" }} onClick={() => setReadMoreWhyTakeCourseText(true)}>Read More...</button>
        //                         </>
        //                         : data?.whyShouldTake
        //                     }
        //                 </p>
        //             }
        //         </div>
        //     </div>
        //     <div style={{ backgroundColor: "#6461E0" }} className='mb-5'>
        //         <div className='container py-4'>
        //             <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        //                 <Row className=''>
        //                     <Col sm={2} className='px-2 d-flex justify-content-center align-items-center gap-3'>
        //                         <Nav variant="pills" className="flex-column gap-3">
        //                             <Nav.Item className='my-3' style={{ border: "1px solid #fff", borderRadius: "8px" }}>
        //                                 <Nav.Link eventKey="first">What to Expect</Nav.Link>
        //                             </Nav.Item>
        //                             <Nav.Item className='my-3'>
        //                                 <Nav.Link eventKey="second" style={{ border: "1px solid #fff", borderRadius: "8px" }}>What you need</Nav.Link>
        //                             </Nav.Item>
        //                         </Nav>
        //                     </Col>
        //                     <Col sm={10} className='align-items-center d-flex justify-content-center px-5 px-md-0'>
        //                         <Tab.Content>
        //                             <Tab.Pane eventKey="first">
        //                                 <ol className='text-white'>
        //                                     <li>Learning breathing exercises</li>
        //                                     <li>Access to different kinds of guided meditation methods</li>
        //                                     <li>Desk-workout techniques to incorporate into work schedules</li>
        //                                     <li>Bedtime stories for a good night’s sleep</li>
        //                                     <li>Recorded self paced tasks to calm your mind</li>
        //                                     <li>Increase your attention span and ability to focus</li>
        //                                     <li>Reduce your regular stress levels and enhance productivity</li>
        //                                 </ol>
        //                             </Tab.Pane>
        //                             <Tab.Pane eventKey="second">
        //                                 <p className='text-white'>
        //                                     Dedicated time each day & a quiet place to practise
        //                                 </p>
        //                             </Tab.Pane>
        //                         </Tab.Content>
        //                     </Col>
        //                 </Row>
        //             </Tab.Container>
        //         </div>
        //     </div>
        //     {/* <div className='container py-5'>
        //         <div className='row'>
        //             <div className='col-md-6'>
        //                 <h3 className='py-3'>Proven to help managing ADHD symptoms</h3>
        //                 <p>
        //                     Research shows that therapeutic treatments can effectively manage ADHD (Show a gradual rise from 0 to 39% to 62%, with treatment progressively increasing)
        //                 </p>
        //                 <p>
        //                     The effectiveness of the course has been verified by our team of experts and with each passing session:
        //                 </p>
        //                 <ul className='px-5 py-3'>
        //                     <li>The participants of the program felt an improvement in their child’s attention span</li>
        //                     <li>The parents who continued to work with their children using the lifetime access of self help content &amp; exercises provided during the course felt the impact of the program for longer duration</li>
        //                     <li>The participants also found the impact of the course as effective as face to face therapy</li>
        //                     <li>80% of the participants would recommend the program to their peers</li>
        //                 </ul>
        //             </div>
        //             <div className='col-md-6'>
        //                 <h3 className='py-3'>Make your & your child's life better</h3>
        //                 <Accordion defaultActiveKey="0" className='py-0'>
        //                     <Accordion.Item eventKey="0" className='py-0'>
        //                         <Accordion.Header className='py-0 fs-3'>
        //                             Week one
        //                         </Accordion.Header>
        //                         <Accordion.Body>
        //                             <ul className='px-5'><li>First Consultation With The Psychologist &amp; Goal Setting For The Course</li><li>Brain Gym Live Exercises With The Psychologist</li><li>Introduction To Worksheets To Improve Focus By Psychologist</li></ul>
        //                         </Accordion.Body>
        //                     </Accordion.Item>
        //                     <Accordion.Item eventKey="1" className='py-0'>
        //                         <Accordion.Header>Week Two</Accordion.Header>
        //                         <Accordion.Body>
        //                             <ul className='px-5'><li>First Consultation With The Psychologist &amp; Goal Setting For The Course</li><li>Brain Gym Live Exercises With The Psychologist</li><li>Introduction To Worksheets To Improve Focus By Psychologist</li></ul>
        //                         </Accordion.Body>
        //                     </Accordion.Item>
        //                 </Accordion>
        //             </div>
        //         </div>
        //     </div> */}
        //     {/* <div className='container my-5 d-flex rounded-2 justify-content-between' style={{ background: "#F1F9FD", height: "220px" }}>
        //         <div className='col-md-2 d-flex flex-column align-items-center position-relative justify-content-end'>
        //             <img src='/assets/img/vasudha-agarwal.jpg' alt='' className='position-absolute' style={{ top: "-20px" }} />
        //             <p className='fs-6 font-w600 py-2'>4.0</p>
        //         </div>
        //         <div className='col-md-9'>
        //             <h3 className='my-3'>About the expert</h3>
        //             <p className='fs-5'>
        //                 Dr. Vasudha Agarwal has 13 years of experience as a life skills trainer & psychologist. She has built this program to ensure that children & their concerns are met with an honest & professional approach.
        //             </p>
        //         </div>
        //     </div> */}
        //     <div className='' id='down-here'>
        //         <div className=''>
        //             <div className='py-5 container'>
        //                 <h3 className='text-center py-3' style={{ color: "#60B4E7", height: "" }}>Testimonials</h3>
        //                 <Swiper pagination={true} navigation={true} modules={[Scrollbar, Pagination, Navigation, A11y]} className="mySwiper col-12 col-md-9 py-md-5">
        //                     <SwiperSlide>
        //                         <div className='border-1 d-flex flex-column align-items-center gap-3'>
        //                             <img src='/assets/img/colon.png' alt='' className='' />
        //                             <h6 className=''>Sumbul</h6>
        //                             <p className=''>Austin</p>
        //                             <p className=''>
        //                                 “I used to get anxiety attacks very often. Even a thought of meeting someone new could throw me in a spin. But after I took this course I feel so much calmer now, I have made some of the meditations a part of my daily ritual now. It is amazing how small exercises can make such a huge difference to you & your life.”
        //                             </p>
        //                             {/* <Link className='text-decoration-none'>READ MORE</Link> */}
        //                         </div>
        //                     </SwiperSlide>
        //                     <SwiperSlide>
        //                         <div className='border-1 d-flex flex-column align-items-center justify-content-center'>
        //                             <img src='/assets/img/colon.png' alt='' className='' />
        //                             <h6 className=''>Rakesh</h6>
        //                             <p className=''>Noida</p>
        //                             <p className=''>
        //                             "As a sales professional in India, the immense and unrelenting pressure on our team began to affect my sleep and mental state. After researching ways to build confidence and manage anxiety, I enrolled in a program that helped me understand and manage my own emotions. With lifetime access to the videos and audios, I return to the course as needed. I learned that changing my own ways of dealing with the external world can ultimately change my world. Thanks for creating such an amazing course."
        //                             </p>
        //                             {/* <Link className='text-decoration-none'>READ MORE</Link> */}
        //                         </div>
        //                     </SwiperSlide>
        //                 </Swiper>
        //             </div>
        //         </div>
        //     </div>
        //     <div style={{ backgroundColor: "#3DACF0" }} className='row justify-content-center p-0 m-0'>
        //         <div className='col-md-4 my-4 px-5 py-3 d-flex align-items-center justify-content-between rounded-4' style={{ backgroundColor: "#000" }}>
        //             <h5 className='' style={{ color: "#B9BBC1" }}>Program Fees <br /><span className='text-white'>
        //                 {
        //                     countryCode === "IN" &&
        //                     <>
        //                         ₹ {data?.price}
        //                     </>
        //                     ||
        //                     <>
        //                         $ {data?.USD_price}
        //                     </>
        //                 }
        //             </span></h5>
        //             <Button id='course_detail_enroll' variant='warning' style={{ backgroundColor: "linear-gradient(90deg, #FF9C00 0%, #FFCE50 100%)" }}
        //                 onClick={() => props.checkout()}
        //             >
        //                 Enroll Now</Button>
        //         </div>
        //     </div>
        //     <div className='container py-5'>
        //         <h4 className='text-center py-3'>Need more help?</h4>
        //         <p className='text-center' style={{ color: "#3A3F4B" }}>
        //             Achieving calm can feel like a huge achievement especially if you have been living with Anxiety for a long time. Once your regular stress and anxiety levels are under control, you can begin to enhance your overall productivity as well, but in a healthier way. If you wish to continue practising meditation and breathing exercises to overcome other mental health risks you might be at, you can explore more guided meditation sessions in our <a className='text-decoration-none' style={{ color: '#0000FF' }} target='_blank' href='https://www.unitedwecare.com/blog/'>content repository.</a> If you wish to speak to an expert for further guidance, we can book a session for you.
        //         </p>
        //     </div>
        // </div>
        //#endregion

    )
}

const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <Link
            style={{ color: '#1B8BCF', textDecoration: "none" }}
            onClick={decoratedOnClick}
        >
            {children}
        </Link>
    );
}

export default CourseDetailID1