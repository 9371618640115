import React from "react";
import Sidebar from "./components/Sidebar";
import Home from "./components/Home";

const CourseInternal = () => {
  return (
    <div style={{ display: "flex", overflow: "hidden" }}>
      <section>
        <Home />
      </section>
    </div>
  );
};

export default CourseInternal;
