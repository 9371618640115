import React from "react";
import "../../styles/courses/Testimonials.css";

const TestimonialCard = (props) => {
 // console.log("prrpos", props);
  return (
    <section>
      <div className="card">
        <div className="card-data">
          <div className="card-heading">{props?.item?.title}</div>
          <div className="card-desc">{props?.item?.description}</div>
        </div>

        <div className="card-user">
          <div className="card-userImage">
            <img src={props?.item?.profile_url} alt="" />
          </div>
          <div className="card-userData">
            <p className="card-userName">{props?.item?.user_name}</p>
            <p className="card-userCourse">{props?.item?.location}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialCard;
