import React from "react";
import Sidebar from "./Sidebar";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import "../styles/Library.css";
import { module_bg_img } from "../assets";

const data = [
  {
    icon: <VideocamOutlinedIcon />,
    text: "Welcome to United We Care!",
  },
  {
    icon: <VideocamOutlinedIcon />,
    text: "Start questionnaire with STELLA!",
  },
  {
    icon: <DescriptionOutlinedIcon />,
    text: "How can CBT help you?",
  },
  {
    icon: <SentimentSatisfiedOutlinedIcon />,
    text: "Mood Monitor",
  },
  {
    icon: <MenuBookIcon />,
    text: "Checksheet",
  },
  {
    icon: <HeadphonesOutlinedIcon />,
    text: "How to overcome depression?",
  },
];

const Library = () => {
  return (
    <section className="library">
      <section className="library-sidebar">
        <Sidebar />
      </section>
      <section className="library-section">
        {/* <div className="library-sections-heading">
          Space from Depression and Anxiety
          <span className="journal-sections-heading-span">
            {" > "} Getting Started
          </span>
        </div> */}
        <div className="library-bg-img">
          <img src={module_bg_img} alt="" loading="lazy" />
        </div>
        <div className="library-container">
          <div className="library-mob">
            <div className="library-mob-heading1"> Module 1</div>
            <div className="library-mob-heading2">Getting Started </div>
            <div className="library-mob-heading3">Sections</div>
          </div>

          <div className="library-name">My Library</div>
          <div className="library-sections-items">
            {data.map((item, i) => (
              <div className="library-sections-item">
                <div className="items">
                  <span class="material-symbols-outlined modules-icons">
                    {item.icon}
                  </span>
                  <div className="modules-text">{item.text}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
};

export default Library;
