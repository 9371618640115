import React from "react";
import "../../styles/courses/Details.css";
import {
  Ellipse405,
  Ellipse406,
  Ellipse407,
  Ellipse408,
  Ellipse409,
  Ellipse410,
  green_check,
} from "./assets";

const Details = () => {
  return (
    <section className="details-section">
      <div className="details-images-left">
        <img src={Ellipse405} width={180} height={180} className="img1" />
        <img src={Ellipse406} width={200} height={200} className="img2" />
        <img src={Ellipse407} width={228} height={228} className="img1" />
      </div>
      <div className="details-main">
        <h1 className="details-heading">
          Uncover the
          <span className="details-blue"> SAFE HEAVEN </span>
          for your mental health
        </h1>

        <div className="details-list">
          <ul>
            <li className="details-list-items">
              {/* <div>
                <img src={green_check} />
              </div> */}
              <span className="details-blue">Carefully crafted </span>programs
              rigorously tested in controlled environments for proven
              effectiveness
            </li>
            <li className="details-list-items">
              <span className="details-blue">
                24*7 AI powered wellness coach, STELLA,{" "}
              </span>{" "}
              to assist you in times of distress in your preferred language
            </li>
            <li className="details-list-items">
              <span className="details-blue">Best in class </span> global
              therapists & coaches with diverse wisdom
            </li>
            <li className="details-list-items">
              <span className="details-blue">Clinically proven </span>{" "}
              assessments & curated content to enhance your emotional well-being
            </li>
          </ul>
        </div>

        <div className="details-btn">Subscribe</div>
      </div>

      <div className="details-images-right">
        <img src={Ellipse408} width={228} height={228} className="img3" />
        <img src={Ellipse409} width={180} height={180} className="img4" />
        <img src={Ellipse410} width={200} height={200} className="img3" />
      </div>
    </section>
  );
};

export default Details;
