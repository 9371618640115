import React from "react";
import { wp_btn, btn, time_clock, unlock } from "./assets";
import "../../styles/courses/RelatedCourses.css";

const CourseCard = (props) => {
  const onCLickCopy = async () => {
    try {
      await navigator?.clipboard?.writeText(props?.courseDataURL);
      console.log("props?.courseDataURL?.course_url", props?.courseDataURL)
      // setResponseMessage(true);
      alert("Link Copied!");
      // setShow(true);
    } catch (err) {
      // console.log(err)
    }
  }

  const onClickWhatsAppOnWeb = () => {
      window.open = (`https://web.whatsapp.com/send?text=${props?.courseDataURL}`,'_blank')
  }

  const onClickWhatsAppOnMobileWeb = () => {
      window.open = (`whatsapp://send?text=${props?.courseDataURL}`,'_blank')
  }

  return (
    <section className="course-card">
      <div className="course-card-container">
        <div className="course-card-image">
          <img src={props?.item?.image} />
        </div>
        <div className="course-card-title">{props?.item?.title}</div>
        <div className="course-details">
          <div className="course-selfPaced">
            <img src={time_clock} width={19} height={19} />
            <div>{props?.item?.duration} </div>{" "}
          </div>
          <div className="course-buttons">
            <div className="course-copy-btn" onClick={onCLickCopy}>
              <img src={btn} />
            </div>
            <div className="course-wp-btn d-md-block d-none" onClick={onClickWhatsAppOnWeb}>
              <img src={wp_btn} />
            </div>
            <div className="course-wp-btn d-md-none d-block" onClick={onClickWhatsAppOnMobileWeb}>
              <img src={wp_btn} />
            </div>
          </div>
        </div>
        <div className="course-card-btn">
          <div>
            <img src={unlock} />
          </div>
          {props?.item?.type === "free" ?
            <div>Start for FREE</div> :
            <div>Subscribe to Enroll</div>
          }
        </div>
      </div>
    </section>
  );
};

export default CourseCard;
