import React, { useEffect, useState } from "react";
import MoodBar from "./MoodBar";
import ProgressBar from "@ramonak/react-progress-bar";
import "../styles/Home.css";
import Sidebar from "./Sidebar";
import {
  home_user,
  home_wp,
  home_copy,
  icon_stroke,
  card,
  module1,
} from "../assets";
import { getCourseContent } from "../_request";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom/dist";
import { courseData } from "../../course/core/models";

const module_thumbnails = [
  {
    img: module1,
    title: "Getting Started",
    section: "9 of 10 sections viewed",
  },
  {
    img: module1,
    title: "Getting Started",
    section: "9 of 10 sections viewed",
  },
  {
    img: module1,
    title: "Getting Started",
    section: "9 of 10 sections viewed",
  },
  {
    img: module1,
    title: "Getting Started",
    section: "9 of 10 sections viewed",
  },
  {
    img: module1,
    title: "Getting Started",
    section: "9 of 10 sections viewed",
  },
  {
    img: module1,
    title: "Getting Started",
    section: "9 of 10 sections viewed",
  },
];

const Home = () => {
  const [courseContent, setCourseContent] = useState();
  const [totalModule, setTotalModule] = useState();
  const [viewedModule, setViewedModule] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    var body = { course_id: 644 };
    getCourseContent(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCourseContent(resp?.data);
        console.log(resp?.data);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, []);

  const handleClickOnModule = (item) => {
    console.log("itemmmmmmm", item)
    navigate(`/course-internal/${item?.id}`)
  };

  useEffect(() => {
    let total = 0;
    let viewed = 0;

    courseContent?.levels?.forEach((level) => {
      total += level?.total;
      viewed += level?.viewed;
    });

    setTotalModule(total);
    setViewedModule(viewed);
  }, [courseContent]);

  return (
    <section style={{ display: "flex", overflowX: "hidden" }}>
      <section className="home-sidebar">
        <Sidebar />
      </section>

      <section className="home-section">
        <div className="home-container">
          <div className="home-upper-section">
            <div className="home-heading">
              <p className="home-heading1">
                COURSE • SELF CARE • {courseContent?.preview_video?.duration}{" "}
                WEEKS
              </p>
            </div>
            <div className="home-sub-heading">
              <p className="home-heading2">{courseContent?.title}</p>
              <div className="home-btns">
                <img src={home_wp} alt="" loading="lazy" />
                <img src={home_copy} alt="" loading="lazy" />
              </div>
            </div>
          </div>
        </div>

        {/* Mood Monitor */}
        <section style={{ width: "80%" }}>
          <MoodBar />
        </section>

        {/* your progress */}
        <section className="progress-section">
          <div className="progress-heading">Your Progress</div>
          <div className="progress-container">
            <div className="progress-sub">Modules</div>
            <div className="progress-text">25% completed</div>
            <div className="progress-bar">
              <ProgressBar completed={25} bgColor="#51AC10" customLabel=" " />
            </div>
          </div>
        </section>

        <section className="video-section">
          <ReactPlayer
            url={courseContent?.preview_video?.video?.player_embed_url}
            controls={true}
          ></ReactPlayer>
        </section>

        {/* module section */}
        <section className="modulesss">
          <div>
            <div className="module-main-heading">All Modules</div>
            <div className="module-sub">{viewedModule} of {totalModule} modules completed</div>
            <div className="module-container">
              {courseContent?.levels?.map((item, i) => (
                <div onClick={() => handleClickOnModule(item)}>
                  <img
                    src={item.image_url}
                    className="module-img"
                    alt=""
                    loading="lazy"
                  />
                  <div className="module-text">
                    <p className="module--heading">{item.name}</p>
                    <p className="module-sub-heading">{item?.viewed} of {item?.total} sections viewed</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>
    </section>
  );
};

export default Home;
