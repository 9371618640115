import React from "react";
import "../styles/Sidebar.css";
import {
  sidebar_home,
  sidebar_mood_monitor,
  sidebar_journal,
  sidebar_stella,
  UWC_logo,
  UWC_QR,
  my_library,
} from "../assets";
import { Link } from "react-router-dom";

const sidebar_data = [
  {
    img: sidebar_home,
    text: "Home",
    link: "/course-internal",
  },
  {
    img: sidebar_stella,
    text: "Talk to Stella",
    link: "/course-internal/talk-to-stella",
  },
  {
    img: sidebar_mood_monitor,
    text: "Mood Monitor",
    link: "/course-internal/module/mood-monitor",
  },
  {
    img: sidebar_journal,
    text: "My Journal",
    link: "/course-internal/module/my-journal",
  },

  {
    img: my_library,
    text: "My Library",
    link: "/course-internal/library",
  },
];

const Sidebar = () => {
  return (
    <section className="sidebar-section">
      <div className="sidebar-list">
        {sidebar_data.map((item, i) => (
          <div
            className="sidebar-li"
            onClick={() => {
              window.location.pathname = item.link;
            }}
          >
            <img src={item.img} className="sidebar-img" alt="" loading="lazy" />
            <p>{item.text}</p>
          </div>
        ))}
      </div>

      <div className="sidebar-app">
        <div className="sidebar-app-heading">
          <img src={UWC_logo} />
          <p>Use App</p>
        </div>
        <div className="sidebar-app-qr">
          <img src={UWC_QR} />
          <p className="sidebar-qr-text">
            Scan this QR code to use our mobile app
          </p>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
