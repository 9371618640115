import React, { useEffect, useState } from "react";
import "../styles/MoodMonitor.css";
import {
  emoji_angry,
  emoji_anxious,
  emoji_sad,
  emoji_happy,
  emoji_unsure,
} from "../assets";
import { setMoodData } from "../_request";
import { useAuth } from "../../../auth";

const mood_emoji = [
  { name: "Angry", emoji: emoji_angry },
  { name: "Anxious", emoji: emoji_anxious },
  { name: "Sad", emoji: emoji_sad },
  { name: "Happy", emoji: emoji_happy },
  { name: "Unsure", emoji: emoji_unsure },
];

const MoodBar = () => {
  const [mood, setMood] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [showSavedText, setShowSavedText] = useState(false);

  var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;

  const handleMoodClick = (mood) => {
    setCurrentDateTime(new Date()); // Update currentDateTime when a mood is selected

    const formattedDateTime = `${currentDateTime.getFullYear()}-${(
      currentDateTime.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDateTime
      .getDate()
      .toString()
      .padStart(2, "0")} ${currentDateTime
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDateTime
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${currentDateTime
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;

    const body = {
      mood: mood,
      user_id: user_id,
      course_id: 644,
      date_time: formattedDateTime,
    };

    setMoodData(body).then((resp) => {
      if (resp?.ResponseCode) {
        console.log(resp?.data?.message);
      }
    });

    setShowSavedText(true);
    setTimeout(() => {
      setShowSavedText(false);
    }, 2000);
  };

  return (
    <section className="mood-section">
      <div className="mood-container">
        <div className="mood-heading">How is your mood today?</div>
      </div>
      <div className="mood-emojis">
        {mood_emoji.map((item, index) => (
          <div
            className="mood-emoji"
            onClick={() => handleMoodClick(item.name)}
          >
            <img src={item.emoji} alt="" loading="lazy" className="emoji" />
            <p className="emoji-text">{item.name}</p>
          </div>
        ))}
      </div>
      {showSavedText && (
        <div className="saved-text">
          <p>Saved!</p>
        </div>
      )}
    </section>
  );
};

export default MoodBar;
