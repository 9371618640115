import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AccessTimeIcon from "../../../assets/icons/clock-icon.svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotCompleted from "../modals/NotCompleted";
import SessionComplete from "../modals/SessionCompleted";

const MarkedSessions = ({
  session,
  status,
  markedSessions,
  openModal,
  handleOpenModal,
  handleCloseModal,
  openModal1,
  handleOpenModal1,
  handleCloseModal1,
}) => {
  const [currPage, setcurrPage] = useState(1);
  const [appointmentId, setAppointmentId] = useState("");
  const cards = 5;
  const lastIndex = currPage * cards;
  const firstIndex = lastIndex - cards;

  const validateSession = (data) => {
    if (data?.session_status === "Not Completed") {
      handleOpenModal1();
    } else {
      handleOpenModal();
    }
    setAppointmentId(data?.appointment_id);
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      <p
        style={{
          color: "black",
          fontSize: "16px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          marginLeft: "40px",
          paddingTop: "30px",
        }}
      >
        {session == "completed"
          ? "Completed Sessions"
          : session === "all"
          ? "Marked Sessions"
          : "Not Completed Sessions"}
      </p>

      <div className="sessionCards--Container custom-card1 gap-3">
        {markedSessions &&
          markedSessions.length > 0 &&
          markedSessions?.map((data, index) => (
            <div key={index} className="">
              <div className="card mb-3 bg-white" style={{ border: '1px solid lightgray'}}>
                <div className="card-body">
                  <div className="d-flex justify-content justify-spaceBetween align-items-center mb-3">
                    <img
                      src={data.client_pofile_picture}
                      alt="User"
                      className="img-fluid rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5
                        className="card-title"
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        {data.client_name}
                      </h5>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "10px",
                          color: "grey",
                          marginLeft: "10px",
                          marginTop: "-8px",
                        }}
                      >
                        Follow-up
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-2 px-1 ">
                    <div
                      style={{
                        FontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      <CalendarMonthIcon
                        style={{ color: "rgb(27, 139, 207)", height: "22px" }}
                      />
                      {data.start_date}
                    </div>
                    <div
                      style={{
                        marginRight: "15px",
                        FontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      <img
                        src={AccessTimeIcon}
                        style={{
                          height: "20px",
                          whiteSpace: "nowrap",
                          paddingRight: "5px",
                        }}
                      />
                      {data.start_time}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        marginLeft: "9px",
                      }}
                      className={`text-${
                        data.session_status !== "Not Completed"
                          ? "success"
                          : "danger"
                      }`}
                    >
                      {data.session_status === "Not Completed"
                        ? "Not Completed"
                        : "Completed"}
                    </p>
                    <p
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "#545454",
                        cursor: "pointer",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 400,
                        marginRight: "20px",
                      }}
                      onClick={() => validateSession(data)}
                    >
                      View Details
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <NotCompleted
          status={"read"}
          appointment_id={appointmentId}
          open={openModal1}
          handleClose={handleCloseModal1}
        />
        <SessionComplete
          status={"read"}
          appointment_id={appointmentId}
          open={openModal}
          handleClose={handleCloseModal}
        />
        {markedSessions && markedSessions.length === 0 && (
          <h4>No Data Found</h4>
        )}
      </div>
      <nav>
        <ul className="pagination">
          {/* <li className="page-item">
            <a href="#" className="page-link"
            onClick={prePage}>Prev</a>


          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default MarkedSessions;
