import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../styles/Modal.css";

const Popup = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-section"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="bg-gray border-0">
        <div className="modal-content">
          <h6 style={{ fontStyle: "italic" }}> {props?.data?.title}</h6>
          <p>{props?.data?.desc}</p>
          <h6>{props?.data?.subHead}</h6>
          <p>{props?.data?.step1}</p>
          <p>{props?.data?.step2}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Popup;
