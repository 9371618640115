import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/CompleteQuiz.css";
import {
  module_bg_img,
  quiz_complete,
  bookmark,
  left_arrow,
  arrow,
  green_tick,
  info_icon,
} from "../assets";
import { courseInternalContentView } from "../_request";
import Popup from "./Modal";

const modal_data = [
  {
    title: "Your personal journal",
    desc: "Your journal is one of the most important tools in this program.",
    subHead: "There are two different ways in which you can use your journal:",
    step1:
      "1. Standard journal entries, Simply type in whatever you like as free text below.",
    step2:
      "2. Journal entries linked to the program, You might like to use your journal to reflect on things you notice and learn as you go through the program content, using the 'Comment in your journal' button which sits below each block of information.",
  },
];

const CompleteQuiz = () => {
  const [quizData, setQuizData] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    var body = {
      userId: 7632,
      subLevelId: 20458,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setQuizData(resp?.data);
        console.log("resp?.dataresp?.data", resp?.data);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, []);

  return (
    <section className="complete-quiz">
      <section className="complete-quiz-sidebar">
        <Sidebar />
      </section>
      <section className="complete-quiz-section">
        <div className="quiz-bg-img">
          <img src={module_bg_img} alt="" loading="lazy" />
        </div>
        <div className="quiz-container">
          <div className="sections-heading">
            Space from Depression and Anxiety
            {">"} Getting Started
          </div>
          <div className="sections-progress">Section 5/6</div>
          <div className="quiz-sections-name">
            <div styles={{ display: "flex", gap: "1rem" }}>
              Quiz Time
              <img
                src={info_icon}
                alt=""
                loading="lazy"
                className="quiz-info-icon"
                onClick={() => setShowModal(true)}
              />
              <Popup
                show={showModal}
                onHide={() => setShowModal(false)}
                data={modal_data[0]}
              />
            </div>
            <div className="quiz-save">
              <div>
                <img
                  src={bookmark}
                  alt=""
                  loading="lazy"
                  className="quiz-bookmark"
                />
              </div>
            </div>
          </div>
          <div>
            <img
              src={quiz_complete}
              alt=""
              loading="lazy"
              className="complete-quiz-img"
            />
          </div>
          <div className="quiz-section-desc">
            You have completed this quiz! Here’s a summary of what we covered...
          </div>
          <div className="complete-quiz-question-container">
            {quizData?.content?.quiz_summary?.map((item) => (
              <div className="complete-items">
                <img
                  src={green_tick}
                  alt=""
                  loading="lazy"
                  className="green-tick"
                />
                <div className="complete-items-text">{item}</div>
              </div>
            ))}
            {/* <div className="complete-items">
              <img
                src={green_tick}
                alt=""
                loading="lazy"
                className="green-tick"
              />
              <div className="complete-items-text">
                Activation comes before motivation!
              </div>
            </div>
            <div className="complete-items">
              <img
                src={green_tick}
                alt=""
                loading="lazy"
                className="green-tick"
              />
              <div className="complete-items-text">
                Rewarding, rather than punishing, yourself is more helpful in
                overcoming depression.
              </div>
            </div>
            <div className="complete-items">
              <img
                src={green_tick}
                alt=""
                loading="lazy"
                className="green-tick"
              />
              <div className="complete-items-text">
                Talking is important as is making changes in your daily life as
                well.
              </div>
            </div>
            <div className="complete-items">
              <img
                src={green_tick}
                alt=""
                loading="lazy"
                className="green-tick"
              />
              <div className="complete-items-text">
                Achieving even tiny goals will boost your sense of achievement
                and your mood.
              </div>
            </div> */}
          </div>

          <div className="complete-quiz-btn">
            <div className="prev-btn">
              <div className="modules-prev-text">Previous Section</div>
              <div className="prev-arrow">
                <img
                  src={left_arrow}
                  alt=""
                  loading="lazy"
                  className="arrow-img"
                />
              </div>
            </div>
            <div className="next-btn">
              <div className="modules-text">Next Section</div>
              <div className="next-arrow">
                <img src={arrow} alt="" loading="lazy" className="arrow-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default CompleteQuiz;
