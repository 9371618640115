import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import "../styles/DailyPractice.css";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import {
  section_bg_img,
  bookmark,
  practice_plus,
  practice_tick1,
  green_tick,
  arrow,
  left_arrow,
  plus_btn_icon,
  info_icon,
  edit_dialog,
  delete__icon,
} from "../assets";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { addPointToCheckSheet, courseInternalContentView } from "../_request";
import Popup from "./Modal";
import { editPointToCheckSheet } from "../_request";
import { deletePointToCheckSheet } from "../_request";

const modal_data = [
  {
    title: "Your personal journal",
    desc: "Your journal is one of the most important tools in this program.",
    subHead: "There are two different ways in which you can use your journal:",
    step1:
      "1. Standard journal entries, Simply type in whatever you like as free text below.",
    step2:
      "2. Journal entries linked to the program, You might like to use your journal to reflect on things you notice and learn as you go through the program content, using the 'Comment in your journal' button which sits below each block of information.",
  },
];

const try_list = [
  { desc: "Try to catch my thoughts" },
  { desc: "Record Mood" },
  { desc: "Reflect in my journal about Thinking Traps" },
  { desc: "Print the Thinking Traps pdf" },
  { desc: "Do the Progressive Muscle Relaxation Exercise" },
];

const practice_list = [
  { desc: "Try to catch my thoughts" },
  { desc: "Record Mood" },
  { desc: "Reflect in my journal about Thinking Traps" },
  { desc: "Print the Thinking Traps pdf" },
  { desc: "Do the Progressive Muscle Relaxation Exercise" },
];

const done_list = [
  { desc: "Try to catch my thoughts" },
  { desc: "Record Mood" },
  { desc: "Reflect in my journal about Thinking Traps" },
];

const DailyPractice = () => {
  const [checkSheetData, setCheckSheetData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(null);
  const [clickedItemId, setClickedItemId] = useState(null);
  const [tryList, setTryList] = useState();
  const [dailyPracticeList, setDailyPracticeList] = useState(["mm"]);
  const [doneList, setDoneList] = useState([]);
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    if (clickedItemId !== null) {
      setShowEdit(clickedItemId);
    }
  }, [clickedItemId]);

  const handleEditClick = (item) => {
    setShowEdit(item?.id);
    setShow(true);
    var body = {
      checksheet_data_id: checkSheetData?.content?.checksheet_data_id,
      checksheet_point_id: item?.id,
      point_name: item?.name,
    };
    editPointToCheckSheet(body).then((resp) => {});
  };

  const handleDeleteClick = (item) => {
    var body = {
      checksheet_data_id: checkSheetData?.content?.checksheet_data_id,
      checksheet_point_id: item?.id,
    };
    deletePointToCheckSheet(body).then((resp) => {});
  };

  const handleClose = () => {
    setShowEdit(null);
    setShow(false);
  };

  useEffect(() => {
    var body = {
      userId: 7632,
      subLevelId: 20459,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCheckSheetData(resp?.data);
        setTryList(resp?.data?.content?.check_points);
        console.log("resp?.dataresp?.data", resp?.data);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, []);

  const HandleAddtoMydailyList = (item) => {
    const newTryList = tryList?.filter((o) => o.id !== item.id);
    setTryList(newTryList);
    setDailyPracticeList([...dailyPracticeList, item]);
  };

  const HandleAddtoCompleteList = (item) => {
    const newDailyPracticeList = dailyPracticeList?.filter(
      (o) => o.id !== item.id
    );
    setDailyPracticeList(newDailyPracticeList);
    setDoneList([...doneList, item]);
  };

  const handleAddStep = () => {
    addPointToCheckSheet().then((resp) => {
      if (resp.ResponseCode === 200) {
        console.log(resp.ResponseMessage);
      }
    });
  };

  const handleAddStepClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    var body = {
      checksheet_data_id: checkSheetData?.content?.checksheet_data_id,
      point_name: textValue,
    };
    addPointToCheckSheet(body).then((resp) => {
      if (resp.ResponseCode === 200) {
        console.log(resp.ResponseMessage);
      }
    });
    setIsEditing(false);
    setTextValue("");
  };

  return (
    <section className="practice">
      <section className="daily-practice-sidebar">
        <Sidebar />
      </section>
      <section className="practice-section">
        <div className="daily-practice-bg-img">
          <img src={section_bg_img} alt="" loading="lazy" />
        </div>

        <div className="practice-container">
          <div className="sections-heading">
            Space from Depression and Anxiety
            {">"} Getting Started
          </div>
          <div className="sections-progress">Section 5/6</div>
          <div className="quiz-sections-name">
            <div styles={{ display: "flex", gap: "1rem" }}>
              Daily Practice
              <img
                src={info_icon}
                alt=""
                loading="lazy"
                className="quiz-info-icon"
                onClick={() => setShowModal(true)}
              />
              <Popup
                show={showModal}
                onHide={() => setShowModal(false)}
                data={modal_data[0]}
              />
            </div>
            <div className="quiz-save">
              <div>
                <img
                  src={bookmark}
                  alt=""
                  loading="lazy"
                  className="quiz-bookmark"
                />
              </div>
            </div>
          </div>
          <div className="practice-section-desc">
            {checkSheetData?.content?.description}
          </div>

          <div className="lists">
            <div className="practice-try-list">
              <div className="list-title try-list-title">Why not Try?</div>
              <div className="list-items">
                {tryList?.map((item, i) => (
                  <div className="try-list-item" key={i}>
                    <img
                      src={practice_plus}
                      alt=""
                      loading="lazy"
                      className="plus-icon"
                      onClick={() => HandleAddtoMydailyList(item)}
                    />
                    <div className="try-item-desc">{item.name}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className="practice-list">
              <div className="list-title practice-list-title">
                My Daily Practice List
              </div>
              <div className="list-items">
                {dailyPracticeList?.map((item, i) => (
                  <div
                    key={item.id}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="practice-list-item">
                      <CheckCircleIcon
                        onClick={() => HandleAddtoCompleteList(item)}
                        className="tick-icon"
                      />
                      <div className="practice-item-desc">{item.name}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        gap: "1rem",
                      }}
                    >
                      {/* <img
                        src={edit_dialog}
                        alt=""
                        loading="lazy"
                        className="edit-dialog-icon"
                        onClick={() => handleEditClick(item.id)}
                      /> */}

                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="edit-popup"
                        >
                          {/* <img
                            src={edit_dialog}
                            alt=""
                            loading="lazy"
                            className="edit-dialog-icon"
                            onClick={() => handleEditClick(item)}
                          /> */}
                          {isEditing ? (
                            <div className="add-step-textarea">
                              <textarea
                                className="add-step-text"
                                value={textValue}
                                onChange={(e) => setTextValue(e.target.value)}
                                rows="1" // Set the number of rows as needed
                                cols="30" // Set the number of columns as needed
                              />
                              <div
                                className="add-step-save-text"
                                onClick={handleSaveClick}
                              >
                                Save
                              </div>
                            </div>
                          ) : (
                            <img
                              src={edit_dialog}
                              alt=""
                              loading="lazy"
                              className="edit-dialog-icon"
                              onClick={() => handleEditClick(item)}
                            />
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            <div className="edit">
                              <ModeEditOutlineOutlinedIcon className="edit-icon" />
                              <p>Edit</p>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            <div className="delete">
                              <img
                                loading="lazy"
                                src={delete__icon}
                                alt=""
                                className="delete__icon"
                                onClick={() => handleDeleteClick(item)}
                              />
                              <p>Delete</p>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                {isEditing ? (
                  <div className="add-step-textarea">
                    <textarea
                      className="add-step-text"
                      value={textValue}
                      onChange={(e) => setTextValue(e.target.value)}
                      rows="1" // Set the number of rows as needed
                      cols="30" // Set the number of columns as needed
                    />
                    <div
                      className="add-step-save-text"
                      onClick={handleSaveClick}
                    >
                      Save
                    </div>
                  </div>
                ) : (
                  <div className="practice-btn" onClick={handleAddStepClick}>
                    <img src={plus_btn_icon} className="plus-icon-btn" />
                    <div className="btn-text" onClick={handleAddStep}>
                      Add Step
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="done-list">
              <div className="list-title practice-list-title">Done</div>
              <div className="list-items">
                {doneList?.map((item, i) => (
                  <div className="done-list-item">
                    <img
                      src={green_tick}
                      alt=""
                      loading="lazy"
                      className="tick-icon"
                    />
                    <div className="practice-item-desc">{item.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="daily-practice-btn">
            <div className="prev-btn">
              <div className="modules-prev-text">Previous Section</div>
              <div className="prev-arrow">
                <img
                  src={left_arrow}
                  alt=""
                  loading="lazy"
                  className="arrow-img"
                />
              </div>
            </div>
            <div className="next-btn">
              <div className="modules-text">Next Section</div>
              <div className="next-arrow">
                <img src={arrow} alt="" loading="lazy" className="arrow-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default DailyPractice;
