import React, { useState } from "react";
import "../../styles/courses/Faq.css";
import { cross, plus } from "./assets";

const data = [
  {
    question: "How can United We Care help improve my mental wellness?",
    answer:
      "At United We Care, we strive to make the world a happier place by providing life-changing support and interventions in the mental health care realm. Through our AI-driven, deep-tech platform, we offer safe, secure and inclusive professional guidance right from the comfort of your home. Our array of mainstream and alternative therapies is designed to bring positive change to thousands of lives globally, empowering you to overcome emotional challenges and enhance your mental wellness.",
  },
  {
    question: "Are your services provided by licensed professionals?",
    answer:
      "At United We Care, we strive to make the world a happier place by providing life-changing support and interventions in the mental health care realm. Through our AI-driven, deep-tech platform, we offer safe, secure and inclusive professional guidance right from the comfort of your home. Our array of mainstream and alternative therapies is designed to bring positive change to thousands of lives globally, empowering you to overcome emotional challenges and enhance your mental wellness.",
  },
  {
    question: "How do I schedule an appointment or access your services?",
    answer:
      "At United We Care, we strive to make the world a happier place by providing life-changing support and interventions in the mental health care realm. Through our AI-driven, deep-tech platform, we offer safe, secure and inclusive professional guidance right from the comfort of your home. Our array of mainstream and alternative therapies is designed to bring positive change to thousands of lives globally, empowering you to overcome emotional challenges and enhance your mental wellness.",
  },
  {
    question: "What types of mental health conditions do you address?",
    answer:
      "At United We Care, we strive to make the world a happier place by providing life-changing support and interventions in the mental health care realm. Through our AI-driven, deep-tech platform, we offer safe, secure and inclusive professional guidance right from the comfort of your home. Our array of mainstream and alternative therapies is designed to bring positive change to thousands of lives globally, empowering you to overcome emotional challenges and enhance your mental wellness.",
  },
  {
    question: "Is my personal information kept confidential and secure?",
    answer:
      "At United We Care, we strive to make the world a happier place by providing life-changing support and interventions in the mental health care realm. Through our AI-driven, deep-tech platform, we offer safe, secure and inclusive professional guidance right from the comfort of your home. Our array of mainstream and alternative therapies is designed to bring positive change to thousands of lives globally, empowering you to overcome emotional challenges and enhance your mental wellness.",
  },
  {
    question:
      "Are there any resources or tools available for self-help or self-assessment?",
    answer:
      "At United We Care, we strive to make the world a happier place by providing life-changing support and interventions in the mental health care realm. Through our AI-driven, deep-tech platform, we offer safe, secure and inclusive professional guidance right from the comfort of your home. Our array of mainstream and alternative therapies is designed to bring positive change to thousands of lives globally, empowering you to overcome emotional challenges and enhance your mental wellness.",
  },
  {
    question: "Can I access your website anonymously?",
    answer:
      "At United We Care, we strive to make the world a happier place by providing life-changing support and interventions in the mental health care realm. Through our AI-driven, deep-tech platform, we offer safe, secure and inclusive professional guidance right from the comfort of your home. Our array of mainstream and alternative therapies is designed to bring positive change to thousands of lives globally, empowering you to overcome emotional challenges and enhance your mental wellness.",
  },
];

const Faq = () => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <section className="faq-section">
      <div className="faq-container">
        <div className="faq-heading">FAQs</div>
        {data.map((item, i) => (
          <div className="item">
            <div className="faq-title" onClick={() => toggle(i)}>
              <h2 className="faq-question">{item.question}</h2>
              <span className="faq-btn">
                {selected === i ? (
                  <img src={cross} className="faq-icon" />
                ) : (
                  <img src={plus} className="faq-icon" />
                )}
              </span>
            </div>
            <div className={selected === i ? "faq-answer show" : "faq-answer"}>
              {item.answer}
            </div>
            <div className="item-border"></div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;
