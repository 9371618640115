import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import Details from "./Details";
import WhatToExpect from "./WhatToExpect";
import Testimonials from "./Testimonials";
import Faq from "./Faq";
import RelatedCourses from "./RelatedCourses";
import { getCourseDetailsV2 } from "./_request";

const Course = () => {
  const [courseData, setCourseData] = useState();

  useEffect(() => {
    var body = { courseId: 61 }
    getCourseDetailsV2(body).then(resp => {
      if (resp.ResponseCode === 200) {
        setCourseData(resp?.data)
      } else {
        console.log(resp?.ResponseMessage)
      }
    })
  }, [])
  return (
    <div style={{ overflowX: "hidden" }}>
      <Hero courseData={courseData} />
      <Details />
      <WhatToExpect courseData={courseData} />
      <Testimonials courseData={courseData} />
      <Faq />
      <RelatedCourses courseData={courseData} />
    </div>
  );
};

export default Course;
