import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UsOnBoardingDashboard from './UsOnBoardingDashboard'
import ExpertAppointmentDB from "../expertDashboard/ExpertAppointmentDB"
import ExpertClientDB from '../expertDashboard/ExpertClientDB'
import DashboardLayout from '../expertDashboard/Dashboard';
import ExpertDashboard from '../expertDashboard/ExpertDashboard'
import ExpertScheduleDB from '../expertDashboard/ExpertScheduleDB'
import ExpertWorksheetsDB from '../expertDashboard/ExpertWorksheetsDB'
import ExpertArticlesDB from '../expertDashboard/ExpertArticlesDB'
import ExpertPostsDB from '../expertDashboard/ExpertPostsDB'
import ExpertNotificationsDB from '../expertDashboard/ExpertNotificationsDB'
import ExpertCommunitiesDB from '../expertDashboard/ExpertCommunitiesDB'
import ExpertHelpCenterDB from '../expertDashboard/ExpertHelpCenterDB'
import ExpertPayoutsDB from '../expertDashboard/ExpertPayoutsDB'
import ExpertSettingsDB from '../expertDashboard/ExpertSettingsDB'
import ExpertChatDB from '../expertDashboard/ExpertChatDB'
import SessionManagement from '../expertDashboard/ExpertSessionManagement'

const index = () => {
    return (
        <Routes>
            <Route element={<DashboardLayout />}>
                <Route path="dashboard" element={<ExpertDashboard />}/>
                <Route path='appointments' element={<ExpertAppointmentDB />} />
                <Route path='sessionmanagement' element={<SessionManagement/>}/>
                <Route path='clients' element={<ExpertClientDB />} />
                <Route path='chat' element={<ExpertChatDB />} />
                <Route path='my-schedule' element={<ExpertScheduleDB />} />
                <Route path='worksheets' element={<ExpertWorksheetsDB /> } />
                <Route path='articles' element={<ExpertArticlesDB /> } />
                <Route path='posts' element={<ExpertPostsDB /> } />
                <Route path='notifications' element={<ExpertNotificationsDB /> } />
                <Route path='communities' element={<ExpertCommunitiesDB /> } />
                <Route path='help-center' element={<ExpertHelpCenterDB /> } />
                <Route path='payouts' element={<ExpertPayoutsDB /> } />
                <Route path='settings' element={<ExpertSettingsDB /> } />
            </Route>
            {/* <Route path='/appointment' element={<Navigate replace to="my-courses" />} /> */}
        </Routes>
    )
}

export default index