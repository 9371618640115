import React, { useContext } from "react";
import {
  badge,
  star,
  assessment,
  duration,
  quiz,
  play_button,
  worksheets,
  videoPlayer,
  wp_btn,
  btn,
} from "./assets";
import "../../styles/courses/Hero.css";
import { useState } from "react";
import Iframe from "react-iframe";
import ReactPlayer from "react-player";
import { useAuth } from "../../auth";
import { AppContext } from "../../context";
import { OODOCoursePaymentService } from "../core/_request";
import { useNavigate } from "react-router";

const Hero = (props) => {
  const { courseData } = props;
  const [readmore, setReadmore] = useState(false);
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  function readmoreHandler() {
    setReadmore(!readmore);
  }
  const info = courseData?.course_description;

  const description = readmore ? info : `${info?.substring(0, 200)} `;

  const onCLickCopy = async () => {
    try {
      await navigator?.clipboard?.writeText(props?.courseData?.course_url);
      // setResponseMessage(true);
      alert("Link Copied!");
      // setShow(true);
    } catch (err) {
      // console.log(err)
    }
  };

  const onClickWhatsAppOnMobileWeb = () => {
    window.open(
      `whatsapp://send?text=${props?.courseData?.course_url}`,
      "_blank"
    );
  };

  const onClickWhatsAppOnWeb = () => {
    window.open(
      `https://web.whatsapp.com/send?text=${props?.courseData?.course_url}`,
      "_blank"
    );
  };

  const handleStartSubscription = () => {
    if (!currentUser) {
      //window.location.href=`/login?retrunurl=/detail/${id}`;
      // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
      localStorage.setItem("retrunurl", `/course-new`);
      setShowLloginPopup(true);
    } else {
      if (courseData?.course_type === "free") {
        var requestt = {
          userId: currentUser?.UserId,
          courseId: courseData?.id,
        };
        OODOCoursePaymentService(requestt).then((_) =>
          navigate("/user-dashboard/course/my-courses")
        );
      } else if (courseData?.course_type === "premium") {
        if (courseData?.inr_price === 0) {
          var requestt = {
            userId: currentUser?.UserId,
            courseId: courseData?.id,
          };
          OODOCoursePaymentService(requestt).then((_) =>
            navigate("/user-dashboard/course/my-courses")
          );
        } else if (courseData?.inr_price > 0) {
          localStorage.setItem("retrunurl", `/course-new`);
          navigate("/plans");
        }
      }
    }
  };

  return (
    <section
      className="hero-section container"
      // style={{ height: readmore ? `${height}+10vh` : height }}
    >
      <div className="hero-main col-md-6">
        <div className="hero-blueHeading">Program {">"} Women Courses</div>

        <div className="hero-content">
          <div className="hero-left">
            <div className="hero-side-buttons">
              <div className="abc">
                <div
                  className="hero-wp-btn d-none d-md-block"
                  onClick={onClickWhatsAppOnWeb}
                >
                  <img src={wp_btn} alt="" loading="lazy" />
                </div>
                <div
                  className="hero-wp-btn d-block d-md-none"
                  onClick={onClickWhatsAppOnMobileWeb}
                >
                  <img src={wp_btn} alt="" loading="lazy" />
                </div>
                <div className="hero-wp-btn" onClick={onCLickCopy}>
                  <img src={btn} alt="" loading="lazy" />
                </div>
              </div>
            </div>
            <h1 className="hero-heading">
              {/* Overcoming Burnout for
              <br /> Women in Dual Roles */}
              {courseData?.course_title}
            </h1>
            <div className="hero-selfPaced">{courseData?.course_duration}</div>

            <div className="hero-info">
              <div className="hero-enrollments">
                <img src={badge} width={31} height={31} alt="" loading="lazy" />
                <span className="hero-infoText">24,000+ enrollments</span>
              </div>
              <div className="hero-rating">
                <img src={star} alt="" loading="lazy" />
                <span className="hero-infoText">
                  {courseData?.course_rating}
                </span>
              </div>
            </div>

            <div className="hero-desc">
              {description}
              <span className="hero-read-more" onClick={readmoreHandler}>
                {readmore ? `Show Less` : `Read More`}
              </span>
            </div>
            <div className="hero-btn" onClick={handleStartSubscription}>
              Start Subscription
            </div>
          </div>
        </div>

        {courseData?.features && (
          <div className="hero-whiteLayout">
            {courseData?.features?.map((item) => (
              <div className="hero-layout-items">
                <img src={item?.icon} alt="" loading="lazy" />
                <span className="hero-layout-text anim1">{item?.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="col-md-6">
        {/* <img src={videoPlayer} /> */}
        <ReactPlayer
          url={courseData?.video?.video?.player_embed_url}
          controls={true}
          light={courseData?.video?.video?.small_thumbnail_url}
          className="video"
          // url='https://embed-ssl.wistia.com/deliveries/67691ab6f4d0a9da7c09dd950ae2961f940c5a85.webp?image_crop_resized=1920x1080'
          styles={{
            borderRadius: "10px",
          }}
        ></ReactPlayer>
      </div>
    </section>
  );
};

export default Hero;
