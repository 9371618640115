import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/Quiz.css";
import {
  bookmark,
  module_bg_img,
  next_que_arrow,
  left_arrow,
  arrow,
  correct_ans,
  info_icon,
  wrong_ans,
} from "../assets";
import CompleteQuiz from "./CompleteQuiz";
import { courseInternalContentView, submitQuizAnswers } from "../_request";
import Popup from "./Modal";
import { useNavigate } from "react-router";

const modal_data = [
  {
    title: "Your personal journal",
    desc: "Your journal is one of the most important tools in this program.",
    subHead: "There are two different ways in which you can use your journal:",
    step1:
      "1. Standard journal entries, Simply type in whatever you like as free text below.",
    step2:
      "2. Journal entries linked to the program, You might like to use your journal to reflect on things you notice and learn as you go through the program content, using the 'Comment in your journal' button which sits below each block of information.",
  },
];
//   {
//     que: "I should wait until I 'feel like' doing something before I do it.",
//     ans: false,
//     explanation:
//       "Never wait until you feel like doing something, especially if you are depressed or low. Many people can get away with this, but people who are susceptible to low mood or depression cannot afford to do this.",
//   },
//   {
//     que: "I should wait until I 'feel like' ",
//     ans: false,
//     explanation: "Never wait until you feel like doing ",
//   },
// ];

const Quiz = () => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(true);
  const [quizData, setQuizData] = useState();
  const [currentCustomDataIndex, setCurrentCustomDataIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [quizAnswers, setQuizAnswers] = useState([]);
  const navigate = useNavigate();
  const currentData =
    quizData?.content?.quiz_questions &&
    quizData?.content?.quiz_questions[currentCustomDataIndex];

  useEffect(() => {
    var body = {
      userId: 7632,
      subLevelId: 20458,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setQuizData(resp?.data);
        console.log("resp?.dataresp?.data", resp?.data);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, []);

  console.log("currentDatacurrentData", quizData?.content?.quiz_questions);
  const handleNextModule = () => {
    debugger;
    // Check if there is a "next" module available
    if (quizData.content.quiz_questions && currentData) {
      //&& currentData.next !== null
      setCurrentCustomDataIndex(currentCustomDataIndex + 1);
    }
  };

  const handleAnswerSelection = (answer, question) => {
    setSelectedAnswer(answer);
    setQuizAnswers((prevAnswers) => [
      ...prevAnswers,
      {
        question_id: question,
        response: answer,
      },
    ]);
    if (currentData?.answer === answer) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  const iconHandler = (answer) => {
    return currentData?.content?.quiz_questions.answer === answer ? (
      <img src={correct_ans} alt="" loading="lazy" className="correct-ans" />
    ) : (
      <img src={wrong_ans} alt="" loading="lazy" className="wrong-ans" />
    );
  };

  const clickHandler = (answer, question) => {
    handleAnswerSelection(answer, question);
    iconHandler(answer);
  };

  const handleNextQuestionClick = () => {
    if (quizData.content.quiz_questions && currentData) {
      //&& currentData.next !== null
      setCurrentCustomDataIndex(currentCustomDataIndex + 1);
      setIsCorrect(null);
      setSelectedAnswer(null);
    }
  };

  const handleFinishQuiz = () => {
    var body = {
      quiz_data_id: quizData?.content?.quiz_data_id,
      responses: quizAnswers
    }
    submitQuizAnswers(body).then((resp) => {

    })
    navigate('/course-internal/module/quiz-completed')
  }

  return (
    <section className="quiz">
      <section className="quiz-sidebar">
        <Sidebar />
      </section>
      <section className="quiz-section">
        <div>
          <div className="quiz-bg-img">
            <img src={module_bg_img} alt="" loading="lazy" />
          </div>
          <div className="quiz-container">
            <div className="sections-heading">
              {quizData?.course_name}
              {">"} Getting Started
            </div>
            <div className="sections-progress">Section 5/6</div>
            <div className="quiz-sections-name">
              <div styles={{ display: "flex", gap: "1rem" }}>
                Quiz Time
                <img
                  src={info_icon}
                  alt=""
                  loading="lazy"
                  className="quiz-info-icon"
                  onClick={() => setShowModal(true)}
                />
                <Popup
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  data={modal_data[0]}
                />
              </div>
              <div className="quiz-save">
                <div>
                  <img
                    src={bookmark}
                    alt=""
                    loading="lazy"
                    className="quiz-bookmark"
                  />
                </div>
              </div>
            </div>

            <div className="quiz-section-desc">
              This quiz aims to help you to learn more about the true nature of
              depression and anxiety.
            </div>
            <div className="quiz-question-numbers">
              Question {currentCustomDataIndex + 1} / {quizData?.content?.quiz_questions?.length}
            </div>
            <div className="quiz-question-container">
              {/* {data.map((quiz, i) => ( */}
              <div>
                <div className="quiz-que">
                  <div>
                    {/* {i + 1} */}
                    {". "}
                  </div>

                  <div>{currentData?.name}</div>
                </div>
                <div className="quiz-btns">
                  <div
                    className="quiz-true-btn"
                    onClick={() => clickHandler("true", currentData?.id)}
                    style={{
                      backgroundColor:
                        isCorrect === null
                          ? "#A68DEF"
                          : selectedAnswer === "true" && !isCorrect
                            ? "#541CD5"
                            : isCorrect && selectedAnswer === "true"
                              ? "#34A001"
                              : "#A68DEF",
                    }}
                  >
                    True
                    <img
                      className="quiz-btn-icons"
                      src={
                        isCorrect === null
                          ? ""
                          : selectedAnswer === "true" && !isCorrect
                            ? wrong_ans
                            : isCorrect && selectedAnswer === "true"
                              ? correct_ans
                              : ""
                      }
                    />
                  </div>
                  <div
                    className="quiz-false-btn"
                    onClick={() => clickHandler("false")}
                    style={{
                      backgroundColor:
                        isCorrect === null
                          ? "#A68DEF"
                          : selectedAnswer === "false" && !isCorrect
                            ? "#541CD5"
                            : isCorrect && selectedAnswer === "false"
                              ? "#34A001"
                              : "#A68DEF",
                    }}
                  >
                    False
                    <img
                      className="quiz-btn-icons"
                      src={
                        isCorrect === null
                          ? ""
                          : selectedAnswer === "false" && !isCorrect
                            ? wrong_ans
                            : isCorrect && selectedAnswer === "false"
                              ? correct_ans
                              : ""
                      }
                    />
                  </div>
                </div>
                {selectedAnswer !== null && (
                  <div className="quiz-explanation">
                    {isCorrect ? (
                      <p> {currentData.summary}</p>
                    ) : (
                      <p> {currentData.summary}</p>
                    )}
                  </div>
                )}
              </div>
              {/* ))} */}

              {currentCustomDataIndex + 1 !== quizData?.content?.quiz_questions?.length &&
                <div
                  className="quiz-next-btn"
                  onClick={() => handleNextQuestionClick()}
                >
                  <div>Next Question</div>
                  <img
                    src={next_que_arrow}
                    className="quiz-next-arrow"
                    alt=""
                    loading="lazy"
                  />
                </div>
                ||
                <div
                  className="quiz-next-btn"
                  onClick={() => handleFinishQuiz()}
                >
                  <div>Finish Quiz</div>
                  <img
                    src={next_que_arrow}
                    className="quiz-next-arrow"
                    alt=""
                    loading="lazy"
                  />
                </div>
              }
            </div>
            <div className="quiz-footer-btns">
              <div className="prev-btn">
                <div className="modules-prev-text">Previous Section</div>
                <div className="prev-arrow">
                  <img
                    src={left_arrow}
                    alt=""
                    loading="lazy"
                    className="arrow-img"
                  />
                </div>
              </div>
              <div className="next-btn">
                <div className="modules-text">Next Section</div>
                <div className="next-arrow">
                  <img
                    src={arrow}
                    alt=""
                    loading="lazy"
                    className="arrow-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Quiz;
