import React from 'react'
import { Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { getCurrencyCode } from './core/commonFun';


const ProfileCard = (props) => {
    const navigate = useNavigate();
    const { item, filters } = props;
    const onClick = (item) => {
        navigate(`/profession/${item?.RegisterYourselfAs}/${item?.Name}/${item.Id}`)
    }
    var ExpertisesArray = item?.Category?.split(',')
    // console.log(item, "item")
    const search = useLocation().search;
    const Profession_url = new URLSearchParams(search).get("Profession");
    const CurrencyCode = getCurrencyCode();

    return (
        <div className="mb-res-30 ani-fade-up mx-1 mx-md-0" data-ani-delay="0" style={{ border: "1px solid #E2E2E1", borderRadius: '15px', background: "#fff", cursor: "pointer", }}>
            <a onClick={() => onClick(item)} className="col-md-12" style={{ textDecoration: "none" }}>
                <div className='d-flex flex-column justify-content-between' style={{ minHeight: 370 }}>
                    {
                        filters === true
                        &&
                        <div className='d-flex align-items-center justify-content-center gap-2'
                            style={{ background: "#FAFAFA", borderRadius: "15px 15px 0px 0px" }}>
                            <img src='/assets/img/Icon.png' alt='' />
                            <p className='fs-6 font-inter' style={{ color: "#EDA02B" }}>Recommended</p>
                        </div>
                        ||
                        <div className='d-flex align-items-center justify-content-center gap-2'
                            style={{ background: "#FAFAFA", borderRadius: "15px 15px 0px 0px" }}>
                            {/* <img src='/assets/img/Icon.png' alt='' />
                            <p className='fs-6 font-inter' style={{ color: "#EDA02B" }}>Recommended</p> */}
                        </div>

                    }


                    <div className='d-flex py-3 px-3 gap-1'>
                        <div className='ms-2 mt-1 col-4'>
                            <img src={item?.ProfileImage} alt='' className='rounded-3 col-12 w-100' style={{ display: "block", overflow: "hidden" }} />
                        </div>
                        <div className='ms-4 col-7' style={{ color: "#667085" }}>
                            <h3 className='font-inter font-w600 fs-5 lh-base'>{item?.Name.substring(0, 17)}</h3>
                            <p className='font-inter fs-6 lh-base'>{item?.RegisterYourselfAs}</p>
                            <p className='font-inter fs-6 lh-base'>Years of Exp. : {item?.Experience}</p>
                            {/* {
                                (Profession_url === 'Psychiatrist')
                                &&
                                <> <span className='font-inter fs-6 lh-base'>Starts @ <span className="font-w500 text-black"> {CurrencyCode} {item?.countryAmount}</span></span><br /> </>
                                ||
                                <> <span className='font-inter fs-6 lh-base'>Starts @ <span className="font-w500 text-black"> {CurrencyCode} {Math.round(item?.countryAmount / 2)}</span></span><br /> </>
                            } */}


                            {item?.Qualifications === null &&
                                <div className='d-flex align-items-center  ' >
                                    <p className='font-inter fs-6 lh-base hideScrollbar' style={{ overflowX: 'scroll', whiteSpace: "nowrap" }}> &nbsp;</p>

                                </div>
                                ||
                                <div className='d-flex align-items-center  ' >
                                    <p className='font-inter fs-6 lh-base hideScrollbar' style={{ overflowX: 'scroll', whiteSpace: "nowrap" }}>{item?.Qualifications} &nbsp;</p>
                                    <span>...</span>
                                </div>

                            }
                            {/* <span className='font-inter fs-6 lh-base'>Speaks: <span className="font-w500 text-black">{item?.Languages?.length > 20 ? item?.Languages?.toString()?.slice(0, 20) + "..." : item?.Languages}</span></span><br /> */}
                            <p className='font-inter fs-6 lh-base'><img src="/assets/img/flag.png" className='me-2' alt="" />{item?.Country}</p>
                        </div>
                    </div>

                    <div className='py-3 px-3'>
                        <p className='d-flex flex-row gap-2 mx-0 my-2 card-font align-items-center hideScrollbar font-inter fs-6' style={{ overflowX: 'scroll' }}>
                            {/* Expertise */}
                            {
                                ExpertisesArray.map((Expertise) => (
                                    <span className='font-inter font-w500 fs-6 rounded-2 py-1 px-2 ' style={{ border: "1px solid #E2E2E1", whiteSpace: "nowrap", }}>
                                        {Expertise}
                                    </span>
                                ))
                            }
                        </p>
                    </div>

                    <div className='d-flex flex-column gap-3 m-0 py-3 px-3' style={{ background: "#F3FFEC", borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className='col-7 col-md-8'>
                                <p className="font-inter fs-6">Next Availability</p>
                                <h6 className="font-inter fs-6 text-black">
                                    {item?.NextAvailability} (IST)</h6>
                            </div>
                            <button id='professional_book_now' className='col-5 col-md-4 px-2 rounded-5 border-0 font-inter py-1' style={{ background: "#9FE770", textAlign: 'center' }}>
                                <a href='' className='book-now-a-professional text-decoration-none fs-6'>Book Now</a>
                            </button>
                        </div>
                    </div>


                    {/* {
                        filters === true
                        &&
                        <div className='d-flex align-items-center gap-2 justify-content-center my-2'>
                            <img src='/assets/img/Mask group(9).png' alt='' />
                            <p className='font-inter fs-6 font-w500' style={{ color: "#1B8BCF" }}>Addition 5% Discount for first clients</p>
                        </div>
                        ||
                        <div className='d-flex align-items-center gap-2 justify-content-center my-2'>
                            <img src='/assets/img/Mask group(9).png' alt='' />
                    <p className='font-inter fs-6 font-w500' style={{ color: "#1B8BCF" }}>Addition 5% Discount for first clients</p>
                        </div>

                    } */}


                </div>
            </a>
        </div>



        // <div className="mb-res-30 ani-fade-up mx-1 mx-md-0" data-ani-delay="0" style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", borderRadius: '12px', background: "#fff", cursor: "pointer", }}>
        //     <a onClick={() => onClick(item)} className="col-md-12" style={{ textDecoration: "none", }}>
        //         <div className='brand-wrap py-3 px-3 d-flex flex-column justify-content-between' style={{ minHeight: 370 }}>
        //             <div className='d-flex' >
        //                 <div className='card-img ms-2 mt-1'>
        //                     <img src={item?.ProfileImage} alt='' className='card-img' style={{ borderRadius: "50%", display: "block", overflow: "hidden" }} />
        //                 </div>
        //                 <div className='ms-4'>
        //                     <h5 style={{}}>{item?.Name} <img src="/assets/img/flag.png" alt=""></img></h5>
        //                     <span className='card-font'>{item?.RegisterYourselfAs}</span><br />
        //                     <span className='card-font'>Areas of Interest : {item?.Experience} years</span><br />
        //                     { 
        //                         (Profession_url === 'Psychiatrist')
        //                         &&
        //                             <> <span className='card-font'>Starts @ <span className="font-w500 text-black"> {CurrencyCode} {item?.countryAmount}</span></span><br /> </>
        //                        ||
        //                           <> <span className='card-font'>Starts @ <span className="font-w500 text-black"> {CurrencyCode} {Math.round(item?.countryAmount/2)}</span></span><br /> </>

        //                     }

        //                     <span className='card-font'>Speaks: <span className="font-w500 text-black">{item?.Languages?.length > 20 ? item?.Languages?.toString()?.slice(0, 20) + "..." : item?.Languages}</span></span><br />
        //                 </div>
        //             </div>
        //             <p className='d-flex flex-row gap-1 mx-0 my-2 card-font align-items-center hideScrollbar' style={{overflowX:'scroll'}}>Expertise:
        //                 {
        //                     ExpertisesArray.map((Expertise) => (
        //                         <span className='font-w400 fs-6 rounded-5 py-1 px-2 ' style={{ border: "1px solid #60B4E7CC", whiteSpace:"nowrap", }}>
        //                             {Expertise}
        //                         </span>
        //                     ))
        //                 }
        //                 {/* <br/> */}
        //                 {/* <p className="w-auto" style={{color:"#3DACF0"}}>Load More...</p> */}
        //                 {/* {item?.Languages.length > 20 ? item?.Languages.toString().slice(0, 20) + "..." : item?.Languages} */}
        //             </p>
        //             <div className='d-flex flex-column px-2 gap-3'>
        //                 {/* <p className='card-font pt-3' style={{ minHeight: 50 }}><b><span className='text-black'>Expertise: </span></b>
        //                     {item?.Category.length > 40 ?
        //                         <>
        //                             <span>{item?.Category.toString().slice(0, 40)}</span>
        //                             <span className='text-black'> Read More...</span>
        //                         </>
        //                         :
        //                         item?.Category}
        //                 </p> */}




        //                 <div className="d-flex justify-content-between w-100">
        //                     <a href='' className='text-decoration-none'>
        //                         <button className='px-3 card-button card-button-style'>Book Now</button>
        //                     </a>
        //                     <div>
        //                         <h6 className="fs-6">Next Availability</h6>
        //                         <h6 className="d-flex align-items-center fs-6 gap-1" style={{ color: "rgb(100, 97, 224)" }}>
        //                             <img src="/assets/img/Vector(11).png" alt="" /> {item?.NextAvailability} (IST)</h6>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </a>
        // </div>
    )
}

export default ProfileCard