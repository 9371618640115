import React from "react";
import "../../styles/courses/WhatToExpect.css";

const ModuleCard = (props) => {
  // console.log("item", props)
  return (
    <div className="module-card-section">
      <div className="module-card-image">
        {props?.image_url && <img src={props?.image_url} />}
      </div>
      <div className="module-card-content">
        <div className="module-card-title">{props?.name}</div>
        <div className="module-card-desc">{props?.description}</div>
      </div>
      {/* <div className="module-arrow">
        <img src={module_arrow} />
      </div> */}
    </div>
  );
};

export default ModuleCard;
