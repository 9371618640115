import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Button,
  NavDropdown,
  Container,
  Offcanvas,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../auth";
import { SignInDrawer } from "./SignInDrawer";
import { AppContext } from "../context";
import { useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { validate_token } from "./core/_request";

const searchPlaceholder = ["an Expert", "a course", "a blog"];

function Header2() {
  const { currentUser, userType, logout } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [changingPlaceholder, setChangingPlaceholder] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [b2BImage, setB2BImage] = useState();
  const navigate = useNavigate();

  const stellaAPIKey = process.env.REACT_APP_STELLAAPIKEY;
  const baseURL = "https://odoo.unitedwecare.ca";

  const location = useLocation();
  const search = useLocation().search;
  const [previousLocation, setPreviousLocation] = useState(null);

  // const headerSearch = (e) => {
  // debugger
  // if (e.key === 'Enter') {
  //   window.location.href = `https://www.unitedwecare.com/search/?query=${searchText}`
  // } else {
  //   setSearchText(e.target.value);
  // }
  // }

  const token_url = new URLSearchParams(search).get("token");
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    validate_token(token_url).then((resp) => {
      if (resp?.ResponseCode === 200) {
        localStorage.setItem("Authorization", resp?.data?.usertoken);

        localStorage.setItem(
          "profile",
          JSON.stringify(resp?.data?.userdetails)
        );
        if (
          resp?.data?.userdetails.header_image &&
          window.location.pathname === "/"
        ) {
          navigate("/user-dashboard");
        } else {
          searchParams.delete("token");
          // Convert the searchParams object back to a string
          const searchString = searchParams.toString();
          // Split the parameters
          const paramsArray = searchString.split("&");

          // Filter out the token parameter
          const filteredParamsArray = paramsArray.filter(
            (param) => !param.startsWith("token=")
          );

          // Reconstruct the query string
          const queryString = filteredParamsArray.join("&").replace(/\+/g, "%20");

          // Create the updated URL

          const updatedURL = `${location.pathname}?${queryString}${location.hash}`;
          
         window.location.href = updatedURL;
         // window.location.href = window.location.href = window.location.origin + window.location.pathname;
        }

        let now = new Date();
        let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
        let firstName = resp?.data?.userdetails?.Name.split(" ")
          .slice(0, 1)
          .join("");
        document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
        console.log(
          "resp?.data?.userdetails?.Name",
          resp?.data?.userdetails?.Name
        );
      }
    });
  }, []);

  useEffect(() => {
    const profileData = localStorage.getItem("profile");
    // console.log("profileData", profileData);

    const parsedProfile = JSON.parse(profileData);
    //console.log("parsedProfile", parsedProfile);

    const headerImage = parsedProfile?.header_image;
    // console.log("headerImage", headerImage);

    if (headerImage) {
      setB2BImage(headerImage);
    }
  }, [b2BImage]);

  const onHeaderSearchIcon = () => {
    window.location.href = `https://www.unitedwecare.com/search/?query=${searchText}`;
  };

  const handleEnterKeyDown = (event) => {
    debugger;
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      onHeaderSearchIcon(); // Trigger the search function
    }
  };

  //const [show, setShow] = useState(false);
  const onLogout = () => {
    //console.log("logout")
    window.location.href = "https://www.unitedwecare.com/";
    logout();
    // navigate("/");
  };

  useEffect(() => {
    const timer = () => {
      setChangingPlaceholder((prevIndex) => {
        if (prevIndex === searchPlaceholder.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });
    };
    setInterval(timer, 5000);

    //cleanup function in order clear the interval timer
    //when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, []);

  const togglePopup = (state) => {
    setMenuOpen(false);
    setShowLloginPopup(state);
  };
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => setMenuOpen(false);

  useEffect(() => {
    if (
      previousLocation &&
      previousLocation.pathname == "/onboarding-stella" &&
      location.pathname !== "/onboarding-stella"
    ) {
      // Alert or perform any other action when the user navigates from the previous location
      //console.log("Kimochi!!")

      const headers = {
        "Content-Type": "application/json",
        "X-API-Key": stellaAPIKey,
      };

      const sessionID = sessionStorage.getItem("stella_s_id");

      const body = {
        senderId: sessionID,
      };

      const getStellaClose = async () => {
        try {
          //setIsLoading(true);

          const response = await fetch(
            `${baseURL}/new-uwc/stella/close/session`,
            {
              method: "POST",
              headers,
              body: JSON.stringify(body),
            }
          );

          if (!response.ok) {
            // Handle error response
            throw new Error("Error fetching languages");
          }

          const jsonData = await response.json();
          console.log("RESPONSE", response);

          if (response.status == 200) {
            console.log("CLEAR");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      getStellaClose();
    }

    // Save the current location as the previous location
    setPreviousLocation(location);
  }, [location, previousLocation]);

  const urlSplit = window.location.href
    .split("?")[0]
    .split("/")
    .includes("user-dashboard");

  return (
    <div
      style={{
        background: "#FFFFFF",
        boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.09)",
      }}
      className="header-container"
    >
      <Navbar
        key={"sm"}
        expand={"sm"}
        className={`${urlSplit ? "d-none" : "col-12"} Header--2`}
      >
        <div
          className="d-flex align-items-center justify-content-between col-12"
          style={{ padding: "5px 5%" }}
        >
          {/* logo Desktop */}
          <Navbar.Brand className="d-none d-md-flex gap-4 align-items-center">
            <a
              href="https://www.unitedwecare.com/"
              className="text-decoration-none"
            >
              <img
                src="/assets/img/Group(3).png"
                alt=""
                width={150}
                height={65}
                style={{ objectFit: "contain" }}
              />
            </a>
            {b2BImage && (
              <img
                src={b2BImage}
                alt=""
                width={110}
                height={65}
                style={{ objectFit: "contain" }}
              />
            )}
          </Navbar.Brand>
          {/* logo Mobile */}
          <Navbar.Brand className="d-block d-md-none">
            <a
              href="https://www.unitedwecare.com/"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none"
            >
              <img
                src="/assets/img/Logo(2).png"
                alt=""
                style={{ objectFit: "contain" }}
              />
            </a>
          </Navbar.Brand>

          {/* Angel One Logo */}
          {b2BImage && (
            <Navbar.Brand className="d-block d-md-none">
              <a href="" className="text-decoration-none">
                <img src={b2BImage} alt="" style={{ objectFit: "contain" }} />
              </a>
            </Navbar.Brand>
          )}

          {/*  If User Login*/}
          {(currentUser && (
            <>
              <Navbar.Toggle
                aria-controls={"offcanvasNavbar-expand-sm"}
                onClick={toggleMenu}
              />
              <Navbar.Offcanvas
                id={"offcanvasNavbar-expand-sm"}
                aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                placement="start"
                // style={{ backgroundColor: "#C4E5F9" }}
                restoreFocus={false}
                show={menuOpen}
                onHide={handleClose}
              >
                <Offcanvas.Header closeButton>
                  <Navbar.Brand className="d-block d-md-none">
                    <a
                      href="https://www.unitedwecare.com/us"
                      className="text-decoration-none"
                    >
                      <img src="/assets/img/Logo(2).png" alt="" />
                    </a>
                  </Navbar.Brand>
                </Offcanvas.Header>
                <Offcanvas.Body className="justify-content-md-end px-4">
                  <Nav className="align-items-baseline align-items-md-center">
                    {/* Mobile View Start */}
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none header-title lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={toggleMenu}
                      href="https://www.unitedwecare.com/about-us/"
                    >
                      About Us
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Care"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-1 dropdown-header"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="/">
                        Professionals
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/course">
                        Programs
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Resource Centre"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-1 dropdown-header-blue"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/blog/">
                        Blogs
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/explore">
                        Wellness Library
                      </NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/?Profession=Psychologist"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Professionals
                      </Link>
                    </Nav.Link> */}
                    {/* <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/course"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Programs
                      </Link>
                    </Nav.Link> */}
                    {!b2BImage && (
                      <Nav.Link
                        className="mx-1 d-block d-md-none"
                        onClick={toggleMenu}
                      >
                        <Link
                          to="/plans"
                          className="text-decoration-none header-title lh-lg"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Plans and Pricing
                        </Link>
                      </Nav.Link>
                    )}
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="For Enterprise"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-1"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">
                        USA
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">
                        India
                      </NavDropdown.Item>
                    </NavDropdown>
                    {/* Mobile View End */}
                    {/* Desktop View Start */}
                    <Nav.Link
                      className="mx-1 d-none d-md-block text-decoration-none header-title lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/about-us/"
                    >
                      About Us
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Care"
                      className="font-inter text-decoration-none header-title lh-lg mx-1 d-none d-md-block text-black header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="/">
                        Professionals
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/course">
                        Programs
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Resource Center"
                      className="font-inter text-decoration-none header-title lh-lg mx-1 d-none d-md-block text-black header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/blog/">
                        Blogs
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/explore">
                        Wellness Library
                      </NavDropdown.Item>
                    </NavDropdown>
                    {!b2BImage && (
                      <Nav.Link
                        className="mx-1 d-none d-md-block" //  d-none d-md-block
                      >
                        <Link
                          to="/plans"
                          className="text-decoration-none header-title lh-lg"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Plans and Pricing
                        </Link>
                      </Nav.Link>
                    )}
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="For Enterprises"
                      className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-1 header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">
                        USA
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">
                        India
                      </NavDropdown.Item>
                    </NavDropdown>
                    {/* Desktop View End */}
                    {/* <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Resource Center"
                      className="font-inter text-decoration-none header-title lh-lg mx-2 d-none d-md-block text-black header-dropdown-blue"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/blog/">Blogs</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/explore">Wellness Library</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="For Enterprises"
                      className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-2 header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">
                        USA
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">India</NavDropdown.Item>
                    </NavDropdown> */}
                    {/* {currentUser && (<Nav.Link className="mx-2 d-none d-md-block"><Link to="/onboarding-stella" className='text-decoration-none header-title lh-lg'>Stella</Link></Nav.Link>)} */}
                    {(currentUser.UserType == userType.ENDUSER && (
                      <>
                        {/* <NavDropdown
                          title={
                            <>
                              Hi{" "}
                              {currentUser?.Name.split(" ")
                                .slice(0, 1)
                                .join("")}
                            </>
                          }
                          id={`offcanvasNavbarDropdown-expand-sm`}
                          className="rounded-5 ms-md-2 px-2 px-md-3 header-title py-1 toggleButton text-white greetUser"
                          style={{
                            backgroundColor: "#541CF5",
                            color: "#fff !important",
                          }}
                        >
                          <NavDropdown.Item className="d-block d-md-none">
                            <Nav.Link onClick={toggleMenu}>
                              <Link
                                to="/dashboard/my-appointments"
                                className="text-decoration-none"
                              >
                                My Appointments
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            // href="#action/3.2"
                            className="d-block d-md-none"
                          >
                            <Nav.Link onClick={toggleMenu}>
                              <Link
                                to="/dashboard/course/"
                                className="text-decoration-none"
                              >
                                My Courses
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item className="d-block d-md-none">
                            <Nav.Link onClick={toggleMenu}>
                              <Link
                                to="/my-subscription"
                                className="text-decoration-none"
                              >
                                My Subscription
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <Nav.Link
                            href="#!"
                            className="d-block d-md-none"
                            onClick={toggleMenu}
                          >
                            <Button
                              className="bg-white border-0 rounded-4 w-100 text-start"
                              style={{ color: "#6384E3" }}
                              onClick={onLogout}
                            >
                              Logout
                            </Button>
                          </Nav.Link>

                          <NavDropdown.Item className="d-none d-md-block">
                            <Nav.Link>
                              <Link
                                to="/dashboard/my-appointments"
                                className="text-decoration-none"
                              >
                                My Appointments
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            href="#action/3.2"
                            className="d-none d-md-block"
                          >
                            <Nav.Link>
                              <Link
                                to="/dashboard/course/"
                                className="text-decoration-none"
                              >
                                My Courses
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            className="d-none d-md-block" //d-none d-md-block
                          >
                            <Nav.Link>
                              <Link
                                to="/my-subscription"
                                className="text-decoration-none"
                              >
                                My Subscription
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <Nav.Link href="#!" className="d-none d-md-block">
                            <Button
                              className="bg-white border-0 rounded-4 w-100 text-start"
                              style={{ color: "#6384E3" }}
                              onClick={onLogout}
                            >
                              Logout
                            </Button>
                          </Nav.Link>
                        </NavDropdown> */}

                        <button
                          className="userLoggedIn--Btn"
                          style={{
                            border: "none",
                            borderRadius: "40px",
                            backgroundColor: "#541cf5",
                            color: "#FFF",
                            fontSize: "14px",
                            fontWeight: "500",
                            padding: "10px 25px",
                          }}
                          onClick={() => {
                            navigate("/user-dashboard");
                          }}
                        >
                          Hi {currentUser?.Name.split(" ").slice(0, 1).join("")}
                        </button>
                      </>
                    )) ||
                      (currentUser.UserType == userType.EXPERT && (
                        <NavDropdown
                          title={
                            <>
                              Hi{" "}
                              {currentUser?.Name.split(" ")
                                .slice(0, 1)
                                .join("")}
                            </>
                          }
                          id="basic-nav-dropdown"
                          className="rounded-3 ms-2 px-2 text-white greetUser"
                          style={{ backgroundColor: "#541CF5", color: "#fff" }}
                        >
                          {/* Mobile View Start */}
                          <NavDropdown.Item className="d-block d-md-none">
                            <Nav.Link onClick={toggleMenu}>
                              <Link
                                to="/expert/dashboard"
                                className="text-decoration-none"
                              >
                                My Dashboard
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            // href="#action/3.1"
                            className="d-none" //d-block d-md-none
                          >
                            <Nav.Link onClick={toggleMenu}>
                              <Link
                                to="/my-subscription"
                                className="text-decoration-none"
                              >
                                My Subscription
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <Nav.Link
                            href="#!"
                            className="text-white d-block d-md-none"
                            onClick={toggleMenu}
                          >
                            <Button
                              className="bg-white border-0 rounded-4 w-100 mx-1 text-start"
                              style={{ color: "#6384E3" }}
                              onClick={onLogout}
                            >
                              Logout
                            </Button>
                          </Nav.Link>
                          {/* Mobile View End */}
                          {/* Desktop View Start */}
                          <NavDropdown.Item
                            // href="#action/3.1"
                            className="d-none d-md-block"
                          >
                            <Nav.Link>
                              <Link
                                to="/expert/dashboard"
                                className="text-decoration-none"
                              >
                                My Dashboard
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            className="d-none" //d-none d-md-block
                          >
                            <Nav.Link>
                              <Link
                                to="/my-subscription"
                                className="text-decoration-none"
                              >
                                My Subscription
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <Nav.Link
                            href="#!"
                            className="text-white d-none d-md-block"
                          >
                            <Button
                              className="bg-white border-0 rounded-4 w-100 mx-1 text-start"
                              style={{ color: "#6384E3" }}
                              onClick={onLogout}
                            >
                              Logout
                            </Button>
                          </Nav.Link>
                          {/* Desktop View End */}
                        </NavDropdown>
                      ))}
                  </Nav>
                  {/* <Form className="d-flex align-items-center">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <Button variant="outline-success">Search</Button>
                  </Form> */}
                  <Form
                    className="d-none d-md-flex align-items-center ms-2 rounded-5"
                    style={{ border: "0.5px solid #541CF5", height: "45px" }}
                  >
                    <InputGroup>
                      <FormControl
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={handleEnterKeyDown}
                        type="search"
                        className="ms-2 border-0 headerSearch"
                        style={{ borderRight: "none" }}
                        placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`}
                      />
                      {/* <InputGroup.Text className="border-0" style={{ background: "none" }} > */}
                      <SearchIcon
                        sx={{
                          color: "#541CF5",
                          display: "block",
                          marginY: "auto",
                          marginRight: "10px",
                        }}
                        onClick={onHeaderSearchIcon}
                      />
                      {/* </InputGroup.Text> */}
                    </InputGroup>
                  </Form>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </>
          )) || (
            <>
              <Navbar.Toggle
                aria-controls={"offcanvasNavbar-expand-sm"}
                onClick={toggleMenu}
              />
              <Navbar.Offcanvas
                id={"offcanvasNavbar-expand-sm"}
                aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                placement="start"
                // style={{ backgroundColor: "#C4E5F9" }}
                className="px-2"
                restoreFocus={false}
                show={menuOpen}
                onHide={handleClose}
              >
                <Offcanvas.Header closeButton>
                  <Navbar.Brand className="d-block d-md-none">
                    <a
                      href="https://www.unitedwecare.com/us"
                      className="text-decoration-none"
                    >
                      <img src="/assets/img/Logo(2).png" alt="" />
                    </a>
                  </Navbar.Brand>
                </Offcanvas.Header>

                <Nav className="align-items-baseline align-items-md-center justify-content-end px-4">
                  {/* Mobile View Start */}
                  <Nav.Link
                    className="mx-1 d-block d-md-none text-decoration-none header-title lh-lg"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={toggleMenu}
                    href="https://www.unitedwecare.com/about-us/"
                  >
                    About Us
                  </Nav.Link>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Care"
                    className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-1 dropdown-header"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="/">Professionals</NavDropdown.Item>
                    <NavDropdown.Item href="/course">Programs</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Resource Centre"
                    className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-1 dropdown-header"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="https://www.unitedwecare.com/blog/">
                      Blogs
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://www.unitedwecare.com/explore">
                      Wellness Library
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link
                    className="mx-1 d-none" //d-block d-md-none
                    onClick={toggleMenu}
                  >
                    <Link
                      to="/plans"
                      className="text-decoration-none header-title lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Plans and Pricing
                    </Link>
                  </Nav.Link>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="For Enterprise"
                    className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-1"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">
                      USA
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">
                      India
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/* Mobile View End */}
                  {/* Desktop View Start */}
                  <Nav.Link
                    className="mx-1 d-none d-md-block text-decoration-none header-title lh-lg"
                    style={{ whiteSpace: "nowrap" }}
                    href="https://www.unitedwecare.com/about-us/"
                  >
                    About Us
                  </Nav.Link>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Care"
                    className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-1 header-dropdown"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="/">Professionals</NavDropdown.Item>
                    <NavDropdown.Item href="/course">Programs</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Resource Center"
                    className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-1 header-dropdown"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="https://www.unitedwecare.com/blog/">
                      Blogs
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://www.unitedwecare.com/explore">
                      Wellness Library
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link
                    className="mx-1 d-none d-md-block" //d-none d-md-block
                  >
                    <Link
                      to="/plans"
                      className="text-decoration-none header-title lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Plans and Pricing
                    </Link>
                  </Nav.Link>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="For Enterprises"
                    className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-1 header-dropdown"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">
                      USA
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">
                      India
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/* <Nav.Link onClick={() => {togglePopup(true);toggleMenu();}} className="text-white d-block d-md-none"><Link className="text-decoration-none header-title lh-lg"><Button className='text-black border-0 rounded-4' style={{ backgroundColor: "#EDEFEB" }}>Login</Button></Link></Nav.Link> */}
                  {/* Desktop View End */}
                  {/* {currentUser && (<Nav.Link className="mx-2 d-none d-md-block"><Link to="/onboarding-stella" className='text-decoration-none header-title lh-lg'>Stella</Link></Nav.Link>)} */}

                  {/* <Nav.Link onClick={() => {togglePopup(true);toggleMenu();}} className="text-white"><Link className="text-decoration-none header-title lh-lg"><Button className='text-black border-0 rounded-4' style={{ backgroundColor: "#EDEFEB" }}>Login</Button></Link></Nav.Link> */}
                  <Nav.Link
                    onClick={() => {
                      togglePopup(true);
                    }}
                    className="text-white"
                  >
                    <Link className="text-decoration-none header-title lh-lg">
                      <Button
                        id="header_login"
                        className="text-white border-0 rounded-4 px-4"
                        style={{ backgroundColor: "#541CF5" }}
                      >
                        Login
                      </Button>
                    </Link>
                  </Nav.Link>
                  <Form
                    className="d-none d-md-flex align-items-center ms-2 rounded-5"
                    style={{ border: "0.5px solid #541CF5", height: "45px" }}
                  >
                    <InputGroup>
                      <FormControl
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={handleEnterKeyDown}
                        type="search"
                        className="ms-2 border-0 headerSearch"
                        style={{ borderRight: "none" }}
                        placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`}
                      />
                      {/* <InputGroup.Text className="border-0" style={{ background: "none" }} > */}
                      <SearchIcon
                        sx={{
                          color: "#541CF5",
                          display: "block",
                          marginY: "auto",
                          marginRight: "10px",
                        }}
                        onClick={onHeaderSearchIcon}
                      />
                      {/* </InputGroup.Text> */}
                    </InputGroup>
                  </Form>
                </Nav>

                <Modal
                  show={ShowLloginPopup}
                  onHide={() => togglePopup(false)}
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  className="m-0 col-4 signInModal"
                  style={{ height: "100vh" }}
                >
                  <SignInDrawer />
                </Modal>
              </Navbar.Offcanvas>
            </>
          )}
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center col-12">
          <Form
            className="d-flex d-md-none align-items-center justify-content-center rounded-2 col-11 my-2"
            style={{ border: "0.5px solid #541CF5", height: "45px" }}
          >
            <InputGroup>
              <FormControl
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleEnterKeyDown}
                type="search"
                className="ms-2 border-0 headerSearch"
                style={{ borderRight: "none" }}
                placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`}
              />
              {/* <InputGroup.Text className="border-0" style={{ background: "none" }} > */}
              <SearchIcon
                sx={{
                  color: "#541CF5",
                  display: "block",
                  marginY: "auto",
                  marginRight: "10px",
                }}
                onClick={onHeaderSearchIcon}
              />
              {/* </InputGroup.Text> */}
            </InputGroup>
          </Form>
        </div>
      </Navbar>
    </div>

    //#region OLD CODE
    // <div style={{ background: "#3DACF0" }}>
    //   <Navbar collapseOnSelect expand="lg" className='container px-3'>
    //     <Navbar.Brand className='d-none d-md-block'><a href='https://www.unitedwecare.com/' className="text-decoration-none"><img src='/assets/img/Unitedwecare-white.png' alt='' /></a></Navbar.Brand>
    //     <Navbar.Brand className='d-block d-md-none'><a href='https://www.unitedwecare.com/' className="text-decoration-none"><img src='/assets/img/uwc-logo-mobile.png' alt='' /></a></Navbar.Brand>
    //     {currentUser &&
    //       <>
    //         {/* {currentUser?.} */}
    //         <Navbar.Brand href="#!" className="d-block d-md-none"><img src={currentUser.ProfileImage} alt='' style={{ width: 40, height: 40, borderRadius: "50%", overflow: "hidden" }} /></Navbar.Brand>
    //         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //         <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>

    //             <Nav className="mr-auto align-items-center">
    //             <Nav.Link className="text-white mx-2"><Link to="/" className="text-decoration-none">Professionals</Link></Nav.Link>
    //             <Nav.Link className="text-white mx-2"><Link to="/course" className="text-decoration-none">Wellness Programs</Link></Nav.Link>
    //             <Nav.Link href='https://www.unitedwecare.com/mental-health-app/?shortlink=m2xm6px8&deep_link_sub1=%257B%2522message%2522%253A%2522hi%2522%257D&c=UWC%20Top%20Menu&pid=unitedwecare.com&deep_link_value=ChatWithStellaScreen&source_caller=ui' className="text-white mx-2 text-decoration-none">Self-Assessments</Nav.Link>
    //             <Nav.Link href='https://www.unitedwecare.com/blog/' className="text-white mx-2 text-decoration-none">Blog</Nav.Link>
    //             {/* <Navbar.Brand href="#!" className="d-none d-md-block mx-2"><img src={currentUser.ProfileImage} alt='' style={{ width: 40, height: 40, borderRadius: "50%", overflow: "hidden" }} /></Navbar.Brand> */}

    //             {
    //             currentUser.UserType==userType.ENDUSER &&
    //             <NavDropdown title={<>Hi {currentUser?.Name.split(' ').slice(0, 1).join('')}</>} id="basic-nav-dropdown" className='text-white rounded-3 ms-3 px-2' style={{backgroundColor:"#FA9A00", color:"#fff"}}>
    //               <NavDropdown.Item href="#action/3.1"><Nav.Link className=""><Link to="/dashboard/my-appointments" className="text-decoration-none">My Appointments</Link></Nav.Link></NavDropdown.Item>
    //               <NavDropdown.Item href="#action/3.2">
    //                 <Nav.Link className=""><Link to="/dashboard/course/" className="text-decoration-none">My Courses</Link></Nav.Link>
    //               </NavDropdown.Item>
    //               <Nav.Link href="#!" className="text-white"><Button className='bg-white border-0 rounded-4 w-100 mx-1 text-start' style={{ color: "#6384E3" }} onClick={onLogout}>Logout</Button></Nav.Link>
    //             </NavDropdown>
    //             ||
    //             currentUser.UserType==userType.EXPERT &&
    //               <NavDropdown title={<>Hi {currentUser?.Name.split(' ').slice(0, 1).join('')}</>} id="basic-nav-dropdown" className='text-white rounded-3 ms-3 px-2' style={{backgroundColor:"#FA9A00", color:"#fff"}}>
    //                 <NavDropdown.Item href="#action/3.1"><Nav.Link className=""><Link to="/expertdashboard/my-appointments" className="text-decoration-none">My Appointments</Link></Nav.Link></NavDropdown.Item>
    //                 <Nav.Link href="#!" className="text-white"><Button className='bg-white border-0 rounded-4 w-100 mx-1 text-start' style={{ color: "#6384E3" }} onClick={onLogout}>Logout</Button></Nav.Link>
    //               </NavDropdown>
    //             }
    //           </Nav>

    //         </Navbar.Collapse>
    //       </>
    //       ||
    //       <>
    //         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //         <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
    //           <Nav className="mr-auto text-white align-items-center">
    //             <Nav.Link className="text-white mx-2"><Link to="/" className="text-decoration-none">Professionals</Link></Nav.Link>
    //             <Nav.Link className="text-white"><Link to="/course" className="text-decoration-none">Wellness Programs</Link></Nav.Link>
    //             <Nav.Link href='https://www.unitedwecare.com/mental-health-app/?shortlink=m2xm6px8&deep_link_sub1=%257B%2522message%2522%253A%2522hi%2522%257D&c=UWC%20Top%20Menu&pid=unitedwecare.com&deep_link_value=ChatWithStellaScreen&source_caller=ui' className="text-white mx-2 text-decoration-none">Self-Assessments</Nav.Link>
    //             <Nav.Link href='https://www.unitedwecare.com/blog/' className="text-white mx-2 text-decoration-none">Blog</Nav.Link>
    //             <Nav.Link className="text-white"><Link to="/login" className="text-decoration-none"><Button className='bg-white border-0 rounded-4' style={{ color: "#6384E3" }}>Login</Button></Link></Nav.Link>
    //           </Nav>
    //         </Navbar.Collapse>
    //       </>
    //     }
    //   </Navbar>
    // </div>
    //#endregion
  );
}

export default Header2;
