import React from 'react'
import { Form } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const PrivacyPolicy = () => {
    return (
        <div>
            <Helmet>
                <title>
                    Privacy Policy - United We Care
                </title>
            </Helmet>
            <div className='' style={{ background: "linear-gradient(180deg, #3DACF0 55.1%, rgba(99, 132, 227, 0) 100%)", padding: 0, margin: 0 }}>
                <div className='container'>
                    <div className="py-5">
                        <h1 className="text-white font-w600 fs-4">Privacy Policy</h1>
                    </div>
                    <div className='bg-white px-5' style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", borderRadius: "12px" }}>
                        <h2 className='fs-5 pt-5'>TERMS OF USE</h2>
                        <p className='py-3'>

                            1. General

                            1. We at United For Her Private Limited, and its technical processors (“Company”, “We”, “Our” or “Us”) are committed to protecting your privacy and grant importance to the confidentiality of Your data over internet. This privacy policy (“Privacy Policy”) read with the Terms of Use available at our website www.unitedwecare.com (“Website”) and/or the mobile application – “UNITEDWECARE” (“Application”) applies to all services offered to persons using or visiting (“Users”, “You” or “Your”) the Website and Application.

                            2. By visiting or using our Website, Application, domain name and any other linked pages, features, content, or any other services we offer from time to time by in connection therewith, or by using the services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent to our collection, use and disclosure of Personal Information (as defined below) in accordance with this Privacy Policy. If you are not agreeable to this, please do not use this Website, Application or services provided by Us.

                            3. We collect, use, share, disclose and protect Your personal data provided while using the services available on the Website/Application. The data collected is used only to enhance the operation of the Website/Application, allowing You to use all the listed features and allowing Us to respond to Your requests/queries in efficient manner. The Personal Information is used strictly in line with Our business purposes and to provide You with useful features.

                            4. In this Privacy Policy, “Personal Information” shall mean information through which a User can be identified, directly or indirectly, in particular by reference to User, their name, email address, phone number, username, password, Internet Protocol (“IP”) address (“Personal Information”). Personal Information does not include anonymized information that has been appropriately processed to make it non-identifiable in order to irreversibly prevent its identification.

                            5. We respect the privacy of Our Users and are committed to protect it in all respects. The information about the User is collected by Us as (i) information supplied by Users; (ii) information automatically tracked during User’s navigation on the Website/Application; and/ or (iii) communicated directly to Us via e-mail or telephone or another channel.

                            6. User acknowledges that this Privacy Policy, together with terms and conditions, forms Our agreement with the User in relation to his use of the Website/App. Any capitalized term used but not defined in this Privacy Policy shall have the meaning attributed to it in our Terms of Use.

                            7. For any service that the User may use, We only collect the data that is strictly necessary (i) for the delivery of the services, (ii) for the performance of a contract for which User is a party, (iii) for which User has given consent for such processing of data, or (iv) for compliance with a legal obligation to which We are subject.

                            8. By using the Website/Application or by otherwise giving us Your Personal Information, You will be deemed to have read, understood and agreed to the practices and policies outlined in this Privacy Policy and agree to be bound by the Privacy Policy. You hereby consent to Our collection, use and sharing, disclosure of Your Personal Information as described in this Privacy Policy. We reserve the right to change, modify, add or delete portions of the terms of this Privacy Policy, at Our sole discretion, at any time.
                            2. Information Collected

                            1. In the course of providing services to You through the Website/Application, We may collect the following information:
                            3. Personal Information such as your name, phone number, username, password, e-mail address and phone number when you register at Website/Application.
                            4. With your permission, we may automatically collect some information about Your hardware devices when you access Website/Application. For example, when You utilize our applications, we may collect your IP address and the type of mobile device You use and Your geographic location. We also may collect information about Your activity on Website/Application, such as information about Your account usage, as well as information about how You interact with the Application (collectively, “User Data”). We may combine this automatically gathered information with other information, including Personal Information we have collected about You.
                            5. If you permit cookies on your browser, we may receive, and store certain types of information known as “cookies” when you access the Website.
                            6. Communications between You and the Company.

                            1. The information collected from You by the Company may constitute ‘personal information’ or ‘sensitive personal data or information’ as defined under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (“SPI Rules”). The SPI Rules define “Sensitive Personal Data or Information” of a person to mean personal information about that person relating to:
                            7. passwords;
                            8. financial information such as bank accounts, credit and debit card details or other payment instrument details;
                            9. physical, physiological and mental health condition;
                            10. sexual orientation;
                            11. medical records and history;
                            12. biometric information;
                            13. information received by body corporate under lawful contract or otherwise;
                            14. visitor details as provided at the time of registration or thereafter; and
                            15. call data records.

                            1. Company will be free to use, collect and disclose information that is freely available in the public domain without Your consent.

                            2. In order to provide services through the Website/Application, We may require the User to provide Us with certain information that personally identifies himself. Personal Information includes the following categories of information:
                            16. Contact data (such as email address, phone number and call recording); and
                            17. Demographic data (such as time zone, postal address and location details); and
                            18. If the User communicates with Us by, for example, e-mail or letter, any information provided in such communication may be collected by Us.
                            19. Financial data (such as account details, e-wallet details, bank account or credit or debit card details or other payment instrument details etc.) that User may have provided Us while using the services provided by Website/Application.

                            1. All such information is stored or used for processing services and under no circumstance are any of these details made available in the public domain. However, We may use this information to share with You additional and/ or upcoming information regarding the services of Website/Application.

                            2. The Website/Application may transmit User Data to the Godaddy servers, to ensure secure storage of. Thereby the User is also subject to the terms of Godaddy server’s policy. The Personal Information is immediately deleted once User deletes the Application or deletes his account exclusively made on the Website, except to the extent it is necessary to store the same under applicable laws. Further, We have implemented commercially reasonable physical, managerial, operational and technical security measures to protect the loss, misuse and alteration and to preserve the security of the Personally Information in Our care.

                            3. Our Application /Website may contain links to third party websites or applications. The User agrees and understands that privacy policies of these websites are not under Our control and the User understands that once he leaves Our servers, use of any information provided by the User shall be governed by the privacy policy of the operator of the site used by them.

                            4. Prior to any direct marketing or commercial operation (for example for sending the newsletters or commercial offers), We will first obtain Users consent, either when creating account on the Website/ Application or later by indicating Your choice in account settings. User will always have the right to withdraw his consent at any time, directly by clicking on the unsubscribe link available on each email received, by modifying his account settings, or by contacting Us at the following email address contactus@unitedwecare.com.

                            5. In accordance with the applicable laws, User has the right to access, to rectify and to object data concerning them. User can access and change the their profile picture, display name and user profile password provided when account is created through the account page or by contacting Us.

                            6. We may disclose any information We deem necessary, in Our sole discretion, to comply with any applicable law, regulation, legal process or governmental request. Such transfer or disclosure shall be for lawful purposes only. Further, such transfer or disclosure of Your sensitive personal information to other third parties shall only be done once it is ensured that the third party receiving such information has implemented the same level of security practices as we have.

                            7. We take Your right to privacy very seriously and other than as specifically stated in this Privacy Policy, will only disclose Your Personal Information in the event it is required to do so by applicable law, rule, regulation, law enforcement agency, governmental official, legal authority or similar requirements or when Company, in its sole discretion, deems it necessary in order to protect its rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk, or to enforce or apply the Terms of Use.
                            20. Security

                            1. The security of User Data and Personal Information is important to Us. We follow generally accepted industry standards to protect the User Data and Personal Information submitted to Us, both during transmission and once We receive it. All information gathered on Our Website/Application is securely stored on the Godaddy server. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. Such information may also be converted to physical form from time to time, if deemed necessary. We take all necessary precautions to protect Your Personal Information both online and off-line, and implement reasonable security practices and measures including certain managerial, technical, operational and physical security control measures that are commensurate with respect to the information being collected and the nature of Our business.

                            2. Although We make best possible efforts to store Personal Information in a secure operating environment that is not open to the public, User must understand that there is no such thing as complete security, and We do not guarantee that there will be no unintended disclosures of Personal Information. If We become aware that Users Personal Information has been disclosed in a manner not in accordance with this Privacy Policy, We will use reasonable efforts to notify such User of the nature and extent of such disclosure as soon as reasonably possible and as permitted by law.

                            3. Depending on the processing of Personal Information, the data may be subject to a different storage period.

                            4. We do not sell Users Personal Information to any advertisers or any third party. We use Users Personal Data to display relevant advertisements, based on his preferences. We make reports related to the advertisement campaigns, in particular statistics, without any reference or information related to the personal information of visitors or users of the Website/App. User can also block advertising cookies in the dedicated “cookies” page.
                            Change to Privacy Policy

                            1. Company may update this Privacy Policy at any time, with or without advance notice. In the event there are significant changes in the way Company treats User’s personally identifiable information, or in the Privacy Policy itself, Company will display a notice on the Website/App or send Users an email, as provided for above, so that You may review the changed terms prior to continuing to use the services. If You object to any of the changes to Our terms, and You no longer wish to avail the services, You may contact contactus@unitedwecare.com to deactivate Your account. Unless stated otherwise, Company’s current Privacy Policy applies to all information that the Company has about You and Your account.

                            2. If a User uses the Website/Application, after a notice of changes published on the Website, such User hereby provides his/her/its consent to the changed terms.
                            This Privacy Policy is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents/ records in various statutes as amended by the Information Technology Act, 2000. This Privacy Policy does not require any physical, electronic or digital signature and is a legally binding document between the User and Us.
                        </p>
                        <Form.Group className="mb-3 pb-5" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Agree & Continue" />
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy