import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import "./OverlayStella.css"

const OverlayStella = () => {

    const [smShow, setSmShow] = useState(false);
    const navigate = useNavigate();

    const onLetsChat = () => {
        navigate('/stella')
    }

    return (
        <div className='conainter-fluid'>
            {/* <Button
                className='border-0 bg-transparent stella-icon w-auto'
                style={{}}
                onClick={() => setSmShow(true)}
                onClick={onLetsChat}
            > */}
                <img src='/assets/img/Group 1000005979.png' alt='' className='stella-icon' onClick={onLetsChat} />
            {/* </Button> */}

            {/* <Modal
                size="sm"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                className='position-relative'
            >
                <Modal.Header closeButton>
                    <img src='/assets/img/Ellipse 390.png' alt='' className='position-absolute' style={{ top: -20, left: "40%" }} />
                </Modal.Header>
                <Modal.Body className='my-3'>
                    <p className='fs-5 text-center lh-lg' style={{ fontFamily: 'Helvetica' }}>👋 Hi, I am Stella.</p>
                    <p className='fs-6 text-center'>Your Mental Health and Wellness Coach</p>
                    <Button
                        onClick={onLetsChat}
                        className='rounded-5 d-block mx-auto mt-3'
                        style={{ background: "#fff", border: "1px solid #1B8BCF", color: "#1B8BCF" }}>
                        Let's Chat
                    </Button>
                </Modal.Body>
            </Modal> */}
        </div>
    )
}

export default OverlayStella;