import React, { useState, useEffect } from "react";
import "../../Styles/style.css"; // Import the custom CSS file

import cardimg from "../../../dashboard/images/Backgroundc.jpg";

// import angry from '../../../dashboard/images/angry.png'
// import anxious from '../../../dashboard/images/anxious.png'
// import sad from '../../../dashboard/images/sad.png'
// import happy from '../../../dashboard/images/happy.png'
// import unsure from '../../../dashboard/images/unsure.png'

// import cardimage1 from '../../images/dcard1.png'
// import cardimage2 from '../../images/dcard2.png'
// import cardimage3 from '../../images/dcard3.png'
// import cardimage4 from '../../images/dcard4.png'

import image1 from "../../images/message (1).png";
import image2 from "../../images/note.png";
import image3 from "../../images/npad.png";

import emoji_angry from "../../images/moods/emoji-angry.gif";
import emoji_anxious from "../../images/moods/emoji-anxious.gif";
import emoji_sad from "../../images/moods/emoji-sad.gif";
import emoji_happy from "../../images/moods/emoji-happy.gif";
import emoji_unsure from "../../images/moods/emoji-unsure.gif";

// import garrow from '../../images/Vector (4).png'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';

import { getRandomExperts } from "../../_request";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../auth";

function DashboardCards() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const moods = [
    {
      uri: "I feel angry",
      name: "Angry",
      emoji: emoji_angry,
    },
    {
      uri: "I feel anxious",
      name: "Anxious",
      emoji: emoji_anxious,
    },
    {
      uri: "I feel sad",
      name: "Sad",
      emoji: emoji_sad,
    },
    {
      uri: "I feel happy",
      name: "Happy",
      emoji: emoji_happy,
    },
    {
      uri: "I feel confused",
      name: "Unsure",
      emoji: emoji_unsure,
    },
  ];

  const [randomExpertsData, setRandomExpertsData] = useState([]);

  useEffect(() => {
    const fetchRandomExperts = async () => {
      try {
        const { experts } = await getRandomExperts(currentUser?.UserId);
        //console.log('randomExperts', randomExperts);
        setRandomExpertsData(experts);
      } catch (error) {
        console.error("Error fetching random experts:", error);
      }
    };

    fetchRandomExperts();
  }, []);

  //console.log("randomExpertsData", randomExpertsData)
  return (
    <div className="userDashboard">
      <div className="userDashboard--cardsContainer mt-2 d-flex gap-4">
        <div className="userDashboard--moods">
          <div className="">
            <img src={cardimg} alt="" className="rounded" />
          </div>
          <div className="card-content">
            <div
              className="card-title text-light text-start"
              style={{ fontSize: "1.1rem" }}
            >
              How is your mood today
            </div>

            <div className="card-body d-flex justify-content-around">
              {moods.map((mood, i) => (
                <div
                  key={i}
                  className="text-center my-auto"
                  onClick={() => {
                    navigate(`/stella?message=${mood.uri}`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={mood.emoji} alt={mood.name} style={{ width: 45, height: 45 }} />
                  <p className="text-light mt-1" style={{ fontWeight: "500" }}>
                    {mood.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="userDashboard--appointments"
          onClick={() => {
            navigate("/");
          }}
        >
          <div className="rounded">
            <img alt="" src={cardimg} className="rounded" />
          </div>
          <div className="card-content">
            <div
              className="d-flex justify-content-around align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/dashboard/my-appointments");
              }}
            >
              <div
                className="card-title text-light"
                style={{ fontSize: "1.1rem" }}
              >
                {" "}
                Book an Appoinment
              </div>
              <svg
                className="w-6 h-6 text-light mb-1"
                style={{ width: "10px", height: "13px" }}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 8 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                />
              </svg>
            </div>

            <div className="card-body d-flex justify-content-evenly px-0">
              {randomExpertsData?.map((exp) => (
                <img
                  key={exp.ID}
                  src={exp.ProfileImage}
                  className="rounded-circle img-fluid my-auto"
                  style={{
                    width: "48px",
                    height: "48px",
                    cursor: "pointer",
                  }}
                  alt=""
                  //onClick={() => clicked("clicked")}
                />
              ))}
            </div>

            <div className="text-center " style={{ cursor: "pointer" }}>
              <div
                className="badge rounded-pill px-5 py-2 m-0 p-0"
                style={{
                  backgroundColor: "rgba(200, 200, 200, 0.259), 0, 0.1)",
                }}
              >
                Explore Experts
              </div>
            </div>
          </div>
        </div>

        <div className="userDashboard--stella">
          <div className="rounded">
            <img src={cardimg} alt="" className="rounded" />
          </div>
          <div className="card-content">
            <div className="d-flex justify-content-between px-2 align-items-center">
              <div
                className="card-title text-light"
                style={{ fontSize: "1.1rem" }}
              >
                Talk to Stella
              </div>

              <svg
                className="w-6 h-6 text-light mb-1"
                style={{ width: "10px", height: "13px" }}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 8 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                />
              </svg>
            </div>

            <div className="card-body">
              <div className="d-flex justify-content-around flex-wrap gap-2 userDashboard--stella__items">
                <span
                  className="badge rounded-pill px-4 py-2 m-0 p-0 my-2 mt-0 d-flex align-items-center gap-1 justify-content-center"
                  style={{
                    backgroundColor: "rgba(200, 200, 200, 0.259), 0, 0.0)",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/stella")}
                >
                  {" "}
                  <img alt="" src={image1} className="mx-1" /> What's on your mind?
                </span>

                <span
                  className="badge rounded-pill px-4 py-2 m-0 p-0 my-2 mt-0 d-flex align-items-center gap-1 justify-content-center"
                  style={{
                    backgroundColor: "rgba(200, 200, 200, 0.259), 0, 0.0)",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/stella`)}
                >
                  {" "}
                  <img alt="" src={image2} className="mx-1" /> Share something
                </span>

                <span
                  className="badge rounded-pill px-5 py-2 m-0 p-0 my-2 mt-0 d-flex align-items-center gap-1 justify-content-center"
                  style={{
                    backgroundColor: "rgba(200, 200, 200, 0.259), 0, 0.0)",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/stella?message=Take a test`)}
                >
                  <img alt="" src={image3} className="mx-1" /> Take an
                  Assessment
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCards;
